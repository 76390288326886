export class Utilities {
  static urlReplace(_pattern, _source, _replacementArray) {
    //this is urlReplace
  }

  static getPaginationNumberArray(
    totalItems: number,
    currentPage: number,
    pageSize: number
  ) {
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (totalPages - currentPage < 2) {
        startPage = totalPages - 4;
      } else {
        startPage = currentPage - 2 <= 0 ? 1 : currentPage - 2;
      }
      if (currentPage <= 2) {
        endPage = 5;
      } else {
        endPage = currentPage + 2 >= totalPages ? totalPages : currentPage + 2;
      }
    }
    return Utilities.rangeFunc(startPage, endPage + 1, false);
  }

  static rangeFunc(start, stop, step) {
    if (stop == null) {
      stop = start || 0;
      start = 0;
    }
    if (!step) {
      step = stop < start ? -1 : 1;
    }

    let length = Math.max(Math.ceil((stop - start) / step), 0);
    let range = Array(length);

    for (let idx = 0; idx < length; idx++, start += step) {
      range[idx] = start;
    }

    return range;
  } 
  static enumSelector(definition) {
    return Object.keys(definition).map((key) => ({
      value: definition[key],
      title: key,
    }));
  }
}
