import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorinterceptorInterceptor implements HttpInterceptor {
  constructor(
    private _injector: Injector,
    private toastr: ToastrService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        // if (error.status === 400) {        
        //   return throwError(() => error);
        // } 
        // else if (error.status === 401) {
        //   // this.toastr.error('Unauthorized 401', 'Error', {
        //   //   timeOut: 3000,
        //   // });
        //    return throwError(() => error);
        // } 
        // else if (error.status === 403) {
        //   this.toastr.error('Forbidden 403', 'Error', {
        //     timeOut: 3000,
        //   });
        //    return throwError(() => error);
        // } 
        // else if (error.status === 404) {
        //   this.toastr.error('Not Found 404', 'Error', {
        //     timeOut: 3000,
        //   });
        //   return throwError(() => error);
        // } 
        // else if (error.status === 408) {
        //   this.toastr.error('TimeOut 408', 'Error', {
        //     timeOut: 3000,
        //   });
        //   return throwError(() => error);
        // } else if (error.status === 409) {
        //   this.toastr.error('Conflict 409', 'Error', {
        //     timeOut: 3000,
        //   });
        //   return throwError(() => error);
        // } else if (error.status === 500) {
        //   this.toastr.error('big bad 500', 'Error', {
        //     timeOut: 3000,
        //   });
        //   return throwError(() => error);
        // } else {
        //   return of();
        // }
        return throwError(() => error);
      })
    );
  }
}

export class HttpError {
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
}
