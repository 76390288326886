export enum StorageType {
  'local',
  'session',
  'cookie',
}
export enum MessageType {
  'success',
  'error',
  'warning',
  'closed',
}
export enum UserType {
  'Reseller',
  'Merchant',
  'Global',
}
