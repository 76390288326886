export enum FeaturesEnum {
    F01 = "AndDoneJs",
    F02 = "Anytime Payments",
    F03 = "PCI compliant",
    F04 = "IPFS Payments",
    F05 = "Split Payments",
    F06 = "Tokenized Payment",
    F07 = "Payment Link",
    F08 = "Supress Transactions",
    F09 = "Card Tokenization",
    F10 = "ACH Tokenization",
    F11 ="Process batch payments",
    F12 = "Embedded Premium Finance",
    F13 = "AndDone Platform Account",
    F14 = "Email Transaction Receipt"
}

export enum FeatureAccessEnum {
    "AndDoneJs" = 1,
    "Anytime Payments" = 2,
    "PCI compliant" = 3,
    "IPFS Payments" = 4,
    "Split Payments" = 5,
    "Tokenized Payment" = 6,
    "Payment Link" = 7,
    "Supress Transactions" = 8,
    "Card Tokenization" = 9,
    "ACH Tokenization" = 10,
    "Process batch payments" = 11,
    "Embedded Premium Finance" = 12,
    "AndDone Platform Account" = 13,
    "Email Transaction Receipt" =  14
  }
  