import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSetting } from 'src/app/common/constants/appsetting.constant';
import { StorageType } from '../enum/storage.enum';
import { StorageService } from '../services/storage.service';
import { CommonApiFuncService } from './common-api-func.service';
import { Utilities } from './utilities';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  loggedInUserData: any = {};

  constructor(
    private router: Router,
    private commonAPIFuncService: CommonApiFuncService,
    private storageService: StorageService
  ) { }

  getAuthData() {
    return JSON.parse(this.storageService.get(StorageType.session, 'auth'));
  }

  getLoggedInData() {
    return JSON.parse(
      this.storageService.get(StorageType.session, 'userDetails')
    );
  }

  getSettingsData() {
    return JSON.parse(
      this.storageService.get(StorageType.session, 'settingsData')
    );
  }

  isValidData(input) {
    if (input !== null && input !== undefined && input !== '') {
      return true;
    }
    return false;
  }

  getFullAddress(addressObj, countryList) {
    let countryListData:any = [];
    let stateList = [];
    let getContriesFromLocal = JSON.parse(this.storageService.get(StorageType.local, 'countryList'))
    countryListData =  getContriesFromLocal ? getContriesFromLocal : this.getAllCountryList();
    stateList = this.getMappedState(addressObj.country);
    let fullAddress = `${addressObj?.addressLine1}${addressObj?.addressLine2}${addressObj?.city}${addressObj?.state}${addressObj?.country}${addressObj?.postalCode}`;
    if (fullAddress !== '') {
      addressObj.country =
        addressObj.country !== '' && addressObj.country != null
          ? this.mapCountryName(addressObj.country, countryListData)
          : '';
      fullAddress = '';
      fullAddress =
        addressObj.addressLine1 !== '' && addressObj.addressLine1 != null && addressObj.addressLine1 !== ' '
          ? `${addressObj.addressLine1}, `
          : '';
      fullAddress =
        addressObj.addressLine2 !== '' && addressObj.addressLine2 != null
          ? `${fullAddress}${addressObj.addressLine2}, `
          : `${fullAddress}`;
      fullAddress =
        addressObj.city !== '' && addressObj.city != null ? `${fullAddress}${addressObj.city}, ` : `${fullAddress}`;
      fullAddress =
        addressObj.state !== '' && addressObj.state != null ? `${fullAddress}${this.mapStateName(addressObj.state, stateList)}, ` : `${fullAddress}`;
      fullAddress =
        addressObj.postalCode !== '' && addressObj.postalCode != null
          ? `${fullAddress}${addressObj.postalCode}, `
          : `${fullAddress}`;
      fullAddress =
        addressObj.country !== '' && addressObj.country != null
          ? `${fullAddress}${addressObj.country}`
          : `${fullAddress}`;
    }
    if (
      fullAddress.trim() === 'USA' ||
      fullAddress.trim() === 'CANADA' ||
      fullAddress.trim() === 'SouthAfrica' ||
      fullAddress.trim() === 'Namibia' ||
      fullAddress.trim() === 'NewZealand'
    ) {
      return '';
    }
    return fullAddress.trim();
  }

  mapCountryName(countryId, countryList) {
    for (let i = 0; i < countryList?.length; i++) {
      const element = countryList[i];
      if (countryList[i].countryId === countryId) {
        return countryList[i].name;
      }
    }
  }

  mapStateName(stateAbbr, stateList) {
    for (let i = 0; i < stateList?.length; i++) {
      if (stateList[i].abbreviation === stateAbbr) {
        return stateList[i].name;
      }
    }
  }

  getAllCountryList() {
    // const checkCountry = this.storageService.get(
    //   StorageType.local,
    //   'countryList'
    // );
    // if (checkCountry) {
    //   return JSON.parse(
    //     this.storageService.get(StorageType.local, 'countryList')
    //   );

    // } else {
      this.commonAPIFuncService.get(`${AppSetting.common.getCountry}?isPayments=true`).subscribe({
        next: (res: any) => {
          this.storageService.save(
            StorageType.local,
            'countryList',
            JSON.stringify(res)
          );
        },
        error: (error) => {
          throw error;
        }
      });
    // }
  }

  getStateList() {
    const checkState = this.storageService.get(StorageType.local, 'stateList');
    if (checkState) {
      return JSON.parse(
        this.storageService.get(StorageType.local, 'stateList')
      );

    } else {
      const url = AppSetting.common.getStateList.replace('{countryId}', '0');
      this.commonAPIFuncService.get(url).subscribe({
        next: (_res: any) => {
          this.storageService.save(
            StorageType.local,
            'stateList',
            JSON.stringify(_res)
          );
        },
        error: (error) => {
          throw error;
        },
      });
    }
  }

  getMappedState(countryId) {
    const stateList = JSON.parse(
      this.storageService.get(StorageType.local, 'stateList')
    );
    return stateList.filter((state) => {
      return state.countryId === countryId;
    });

  }

  getCountryListWithCountryCodes() {
    const countries = JSON.parse(
      this.storageService.get(StorageType.local, 'countryList')
    );
    countries.forEach((element: any) => {
      element.countryAndCode = `${element.name} (${element.isdCode})`;
    });
    return countries;
  }

  getTimeZoneList() {
    return this.commonAPIFuncService.get(AppSetting.common.getTimeZone).pipe(
      tap((_) => this.log(`fetched`)),
      catchError(this.handleError('fetch'))
    );
  }

  // get formatted date
  getFormattedDate(date) {
    if (date) {
      return moment(date).format('MM-DD-YYYY');
    }
  }

  getCalenderFormattedDate(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    }
  }

  // get previous date from current date
  getPreviousDate(value) {
    const date = moment().subtract(value, 'days').startOf('day')['_d'];
    return moment(date).format('MM-DD-YYYY');
  }

  // get next date from current date
  getNextDate(value) {
    const date = moment().add(value, 'days').startOf('day')['_d'];
    return moment(date).format('MM-DD-YYYY');
  }

  // pagination logic---------------------------------------------------------
  initiatePager(resultsPerPage?) {
    const pager: any = {};
    pager.currentPage = 1;
    pager.totalPages = 0;
    pager.resultPerPage = resultsPerPage ? resultsPerPage : 10;
    pager.totalResults = 0;
    pager.pages = [];
    pager.data = [];
    return pager;
  }

  setPager(result, pageNumber, pager) {
    const data = result.data;
    const dataCount = result.totalRowCount;
    const pageCount = Math.ceil(dataCount / pager.resultPerPage);
    if (dataCount > 0) {
      pager.totalPages = pageCount;
      pager.results = dataCount;
      pager.totalResults = dataCount;
      pager.data = data;
      pager.currentResults = data?.length;
      pager.currentPage = pageNumber;
      pager.pages = Utilities.getPaginationNumberArray(
        dataCount,
        pageNumber,
        pager.resultPerPage
      );
      pager.startRecord =
        pager.currentPage * pager.resultPerPage - (pager.resultPerPage - 1);
      pager.endRecord =
        pager.currentPage * pager.resultPerPage > pager.totalResults
          ? pager.totalResults
          : pager.currentPage * pager.resultPerPage;
    } else {
      this.initiatePager();
    }
    return pager;
  }

  calculatePageStartRow(pageNumber, resultPerPage) {
    return (pageNumber * 1 - 1) * (resultPerPage * 1);
  }
  // pagination logic---------------------------------------------------------

  buildQuery(data) {
    let queryData = '';
    for (const prop in data) {
      if (
        data[prop] !== '' &&
        data[prop] !== 'undefined' &&
        data[prop] !== null
      ) {
        if (queryData === '') {
          queryData = '?' + prop + '=' + encodeURIComponent(data[prop]);
        } else {
          queryData += '&' + prop + '=' + encodeURIComponent(data[prop]);
        }
      }
    }
    return queryData;
  }

  logOut() {
    try {
      sessionStorage.clear();
      localStorage.clear();
    } catch (Execption) {
      sessionStorage.clear();
      localStorage.clear();
    }
    this.router.navigate(['/login']);
  }

  handleError<T>(operation = 'operation', _result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.message}`);
      return throwError(() => error);
    };
  }

  /** Log a HeroService message with the MessageService */
  log(_message: string) {
    //this is a log method
  }
}
