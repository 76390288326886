import { Component, OnInit } from '@angular/core';
import { HeaderStatusService } from '../../../common/services/header-status.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear : number; 
  pagesStatus: any; 
  constructor(private headerService: HeaderStatusService) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear()
    this.headerService.castHeaderStatus.subscribe((data) => {
      this.pagesStatus = data;
    });
  }

  getPrivacyPolicy() {
    let headerStatus = {
      contactInfo: 'pending',
      paymentInfo: 'pending',
      summaryInfo: 'pending',
      termsUse: 'inactive',
      privacyPolicy: 'active',
    };
    this.updateStatus(headerStatus);
  }

  getTermsUse() {
    let headerStatus = {
      contactInfo: 'pending',
      paymentInfo: 'pending',
      summaryInfo: 'pending',
      termsUse: 'active',
      privacyPolicy: 'inactive',
    };
    this.updateStatus(headerStatus);
  }

  updateStatus(data) {
    this.headerService.updateStatus(data);
  }
}
