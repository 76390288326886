export namespace Messages {
  export const required = 'Please enter {name}.';
  export const requiredImage = 'Please upload {name}.';
  export const minlength = '{name} must be {min} digits/characters long.';
  export const maxlength =
    '{name} cannot be more than {max} digits/characters.';
  export const email = '"Please enter a valid {name}.';
  export const pattern = 'Please enter a valid {name}.';
  export const amountpattern =
    '{name} should be in the range of 0-999999999.99 with max. of 2 decimals';
  export const matchPassword = '{name} is not matched.';
  export const invalidmobile = 'Only number is allowed.';
  export const isNumberOnly = 'Only number is allowed.';
  export const blankPassword = '{name} should not be blank.';
  export const cardNumber = '{name} not valid.';
  export const amount = 'Please enter {name} greater than 0';
  export const expiryDate = 'Please enter an {name}';
  export const expiryDateInvalid = 'Please enter a valid {name}';
  export const countrySelect = 'Please select {name}';
  export const stateSelect = 'Please select {name}';
  export const buyRate = '{name} should be greater than or equal to 0';
  export const emailWithMergeFields = `{name} is not valid (multiple values should be seperated with ; )`;
  export const numberLimitPattern = '{name} should be in the range of 1-60';
  export const DiscountAmount = '{name} should be less than Amount';
  export const Discount = '{name} should be less than Amount';
  export const MinAmount = '{name} should be less than Maximum Amount';
  export const blankVerifyAccountNumber = 'Please re-enter a {name}';
  export const matchAccountNumber =
    '{name} number must match with bank account number';
  export const NameOnCard = 'Please enter a valid Name On Card.';
}
