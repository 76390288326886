import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { ToastrService } from 'ngx-toastr';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { StorageService } from 'src/app/common/services/storage.service';
import { ValidationConstant } from 'src/app/common/validation/validation.constant';
import { Validator } from 'src/app/common/validation/validator';
import { PaymentInformationService } from '../../services/payment-information.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {
  validator: Validator;
  sendMailForm: any;
  sendMailFormErrors: any = {};
  loader: boolean = false;
  paymentsResponseData: any;
  agentSettingsData: any;
  isMailSent = false;
  config = {
    email: {
      required: { name: ValidationConstant.contactInfo.email.name },
      pattern: { name: ValidationConstant.contactInfo.email.name },
      maxlength: {
        name: ValidationConstant.contactInfo.email.name,
        max: ValidationConstant.contactInfo.email.maxLength.toString(),
      },
    },
  };
  constructor(
    private el: ElementRef,
    private fb: UntypedFormBuilder,
    private storageService: StorageService,
    private paymentInformationService: PaymentInformationService,
    public bsModalRef: BsModalRef
  ) // public toastr: ToastrService
  {
    this.validator = new Validator(this.config);
  }

  ngOnInit(): void {
    // get data from sessionStorage
    let paymentsResponse = this.storageService.get(
      StorageType.session,
      'paymentsResponse'
    );
    this.paymentsResponseData = JSON.parse(paymentsResponse || '{ }');

    let agentSettingsData = this.storageService.get(
      StorageType.session,
      'agentSettings'
    );
    // get data from sessionStorage END

    this.agentSettingsData = JSON.parse(agentSettingsData || '{ }');
    this.SendEmailForm();
    this.sendMailForm.valueChanges.subscribe((_data: any) =>
      this.onValueChanged('sendEmail')
    );
  }

  // of from value change
  onValueChanged(data?: any) {
    this.isMailSent = false;
    if (data == 'sendEmail') {
      if (this.sendMailForm.valid) {
        this.validator.validateAllFormFields(this.sendMailForm);
        this.sendMailFormErrors = this.validator.validate(this.sendMailForm);
        return;
      }
      this.validator.validateAllFormFields(this.sendMailForm);
      this.sendMailFormErrors = this.validator.validate(this.sendMailForm);
    }
  }
  SendEmailForm() {
    this.sendMailForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(ValidationConstant.contactInfo.email.maxLength),
          Validators.pattern(ValidationConstant.email_regex),
        ],
      ],
    });
  }

  ngAfterViewInit() {
    const refinput = this.el.nativeElement.querySelectorAll('input');

    const label = this.el.nativeElement.querySelectorAll('label');
    if (refinput.length > 0) {
      for (let item of refinput) {
        if (item.type == 'text' || item.type == 'phone') {
          let label = item.parentNode.getElementsByTagName('label')[0];
          let inline = item.parentNode;
          item.addEventListener('focus', () => {
            label.classList.add('animate');
          });
          item.addEventListener('focusout', () => {
            if (item.value == '') label.classList.remove('animate');
          });
        }
      }
    }
  }

  // Send API Method call
  sendMail() {
    if (!this.sendMailForm.valid) {
      this.validator.validateAllFormFields(this.sendMailForm);
      this.sendMailFormErrors = this.validator.validate(this.sendMailForm);
      return;
    }
    this.loader = true;
    const obj = {
      token: this.agentSettingsData.token,
      type: 'AnytimePayment',
      transactionId: this.paymentsResponseData?.transactionId,
      recipientEmailId: this.sendMailForm.controls.email.value,
      UserType: 'Customer',
    };

    this.paymentInformationService.sendReceiptEmail(obj).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.isMailSent = true;
      },
      error: (error) => {
        this.loader = false;
      },
    });
  }

  closePopup() {
    this.bsModalRef.hide();
  }
}
