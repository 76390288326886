import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ValidationConstant } from './validation.constant';
// import { pairwise, startWith } from 'rxjs/operators';

export class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
    const password = AC?.get('newPassword').value; // to get value in input tag
    const confirmPassword = AC?.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({ matchPassword: true });
    } else if (confirmPassword && confirmPassword.trim() === '') {
      AC.get('newPassword').setErrors({ blankPassword: true });
      AC.get('confirmPassword').setErrors({ blankPassword: true });
    } else {
      return null;
    }
  }

  static MatchAccountNumber(AC: AbstractControl) {
    const accountNumber = AC?.get('accountNumber').value; // to get value in input tag
    const verifyAccountNumber = AC?.get('verifyAccountNumber').value; // to get value in input tag
    if (accountNumber && accountNumber !== verifyAccountNumber) {
      AC.get('verifyAccountNumber').setErrors({ matchAccountNumber: true });
    }
    if (
      (verifyAccountNumber == undefined ||
        verifyAccountNumber?.trim() === '' ||
        verifyAccountNumber == null) &&
      (accountNumber == undefined ||
        accountNumber?.trim() === '' ||
        accountNumber == null)
    ) {
      //AC.get('accountNumber').setErrors({ blankVerifyAccountNumber: true });
      AC.get('verifyAccountNumber').setErrors({
        blankVerifyAccountNumber: true,
      });
    } else {
      return null;
    }
  }

  static isMobileNumber(AC: AbstractControl) {
    const mobileNo = AC?.get('MobileNoHide').value; // to get value in input tag
    const countryCode = AC?.get('countryCode').value; // to get value in input tag

    if (mobileNo && countryCode) {
      let valid = false;

      if (valid) {
        return null;
      } else {
        AC?.get('MobileNo').setErrors({ invalidmobile: true });
      }
    }
  }
}

export class CardValidation {
  static tipAmount(control: UntypedFormControl) {
    const amount = control?.get('tipAmount')?.value;
    if (amount !== null) {
      if (amount < 0) {
        control?.get('tipAmount')?.setErrors({ amount: true });
      } else if (amount > 0 && !/^[\d]{1,9}(\.[\d]{1,2})?$/.test(amount)) {
        control?.get('tipAmount')?.setErrors({ amountpattern: true });
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static taxAmount(control: UntypedFormControl) {
    const amount = control?.get('taxAmount')?.value;
    if (amount !== null) {
      if (amount < 0) {
        control?.get('taxAmount')?.setErrors({ amount: true });
      } else if (amount > 0 && !/^[\d]{1,9}(\.[\d]{1,2})?$/.test(amount)) {
        control?.get('taxAmount')?.setErrors({ amountpattern: true });
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static cvvValidation(control: UntypedFormControl) {
    const cvDataStatus = control?.get('cvDataStatus')?.value;
    if (cvDataStatus === 'AV') {
      return true;
    } else {
      return false;
    }
  }

  static card_Expiry(control: UntypedFormControl) {
    let cardExpiry = control?.get('cardExpiry')?.value;
    if (cardExpiry !== null && cardExpiry !== undefined) {
      if (cardExpiry.length == 5) {
        cardExpiry =
          cardExpiry.slice(0, 2) + cardExpiry.slice(3, cardExpiry.length);
      }
      if (/[^0-9]+/.test(cardExpiry)) {
        control?.get('cardExpiry')?.setErrors({ expiryDate: true });
      }
      if (cardExpiry.length > 0 && cardExpiry.length < 4) {
        control?.get('cardExpiry')?.setErrors({ expiryDateInvalid: true });
      }
      if (cardExpiry.length === 4) {
        const yy = Number(cardExpiry.substr(2));
        const mm = Number(cardExpiry.substr(0, 2));
        if (mm <= 12 && mm > 0) {
          let expirationDate;
          if (mm === 12) {
            expirationDate = new Date(2000 + yy, mm - 1, 31);
          } else {
            expirationDate = new Date(2000 + yy, mm, 1);
            expirationDate.setDate(expirationDate.getDate() - 1);
          }
          const todaysDate = Date.now();
          if (expirationDate > todaysDate) {
            return null;
          } else {
            control?.get('cardExpiry')?.setErrors({ expiryDateInvalid: true });
          }
        } else {
          control?.get('cardExpiry')?.setErrors({ expiryDateInvalid: true });
        }
      }
    } else {
      return null;
    }
  }

  static country_select(control: UntypedFormControl) {
    let countrySelect = control?.get('country')?.value;
    if (countrySelect == '') {
      control?.get('country')?.setErrors({ countrySelect: true });
    } else {
      return null;
    }
  }

  static state_select(control: UntypedFormControl) {
    let stateSelect = control?.get('state')?.value;
    if (stateSelect == '') {
      control?.get('state')?.setErrors({ stateSelect: true });
    } else {
      return null;
    }
  }

  static valid_card(control: UntypedFormControl) {
    let validCard = false;
    let cardNo = '';
    if (control['controls'].maskCardNumber !== undefined) {
      cardNo = control?.get('maskCardNumber')?.value;
    }

    if (control['controls'].creditCardNumber !== undefined) {
      cardNo = control?.get('creditCardNumber')?.value;
    }

    if (
      control['controls'].cardNumber !== undefined &&
      control.get('cardNumber')?.value !== null
    ) {
      cardNo = control?.get('cardNumber')?.value.replace(/\s/g, '');
    }

    // accept only digits, dashes or spaces
    if (cardNo !== '' && cardNo !== null && cardNo !== undefined) {
      if (cardNo.length === 8 && cardNo.includes('****')) {
        if (
          control['controls'].CustomerAccountId !== undefined &&
          control['controls'].CustomerAccountId.value !== null
        ) {
          return null;
        }
        if (
          control['controls'].IsEdit !== undefined &&
          control['controls'].IsEdit.value === true
        ) {
          return null;
        }
      }

      if (!ValidationConstant.cardNumberRegex.test(cardNo)) {
        if (control['controls'].maskCardNumber !== undefined) {
          control?.get('maskCardNumber')?.setErrors({ cardNumber: true });
        }
        if (control['controls'].cardNumber !== undefined) {
          control?.get('cardNumber')?.setErrors({ cardNumber: true });
        }

        if (control['controls'].creditCardNumber !== undefined) {
          control?.get('creditCardNumber')?.setErrors({ cardNumber: true });
        }
      }

      // The Luhn Algorithm. It's so pretty.
      let nCheck = 0;
      let nDigit = 0;
      let bEven = false;
      cardNo = cardNo.replace(/\D/g, '');

      for (let n = cardNo.length - 1; n >= 0; n--) {
        const cDigit = cardNo.charAt(n);
        nDigit = parseInt(cDigit, 10);
        if (bEven) {
          if ((nDigit *= 2) > 9) {
            nDigit -= 9;
          }
        }

        nCheck += nDigit;
        bEven = !bEven;
      }
      validCard = nCheck % 10 === 0;

      if (validCard) {
        return null;
      } else {
        if (control['controls'].maskCardNumber !== undefined) {
          control?.get('maskCardNumber')?.setErrors({ cardNumber: true });
        }
        if (control['controls'].creditCardNumber !== undefined) {
          control?.get('creditCardNumber')?.setErrors({ cardNumber: true });
        }
        if (control['controls'].cardNumber !== undefined) {
          control?.get('cardNumber')?.setErrors({ cardNumber: true });
        }
      }
    }
  }

  static matchAccountNumber(control: UntypedFormControl) {
    const accountNo = control?.get('AccountNo')?.value;
    const accountNoVerification = control?.get('AccountNoVerification')?.value;
    if (accountNo !== accountNoVerification) {
      control
        ?.get('AccountNoVerification')
        ?.setErrors({ matchCardNumber: true });
    } else {
      return null;
    }
  }
}
