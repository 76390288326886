import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SecureComponent } from './secure.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContactInfoComponent } from './payment/component/contact-info/contact-info.component';
import { PaymentInformationComponent } from './payment/component/payment-information/payment-information.component';
import { PaymentSummaryComponent } from './payment/component/payment-summary/payment-summary.component';
import { PrivacyPolicyComponent } from './payment/component/privacy-policy/privacy-policy.component';
import { TermsUseComponent } from './payment/component/terms-use/terms-use.component';
import { PaymentComponent } from './payment/payment.component';
import {
  BlockCopyPasteDirective,
  BlockCopyPasteCutDirective,
} from '../../common/directives/blockcopypaste.directive';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { CustomFormatPhonePipe } from '../../common/pipe/custom-format-phone.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../layouts/loader/loader.component';
import { FooterComponent } from '../layouts/footer/footer.component';
import { HeaderComponent } from '../layouts/header/header.component';
import { AccessDeniedComponent } from './payment/component/access-denied/access-denied.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPrintElementModule } from 'ngx-print-element';
import { SendEmailComponent } from './payment/component/send-email/send-email.component';
import { CustomDropdownComponent } from './payment/component/custom-dropdown/custom-dropdown.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TranslateModule,
    NgxPrintElementModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    SecureComponent,
    ContactInfoComponent,
    PaymentInformationComponent,
    PaymentSummaryComponent,
    PrivacyPolicyComponent,
    TermsUseComponent,
    PaymentComponent,
    CustomFormatPhonePipe,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    AccessDeniedComponent,
    BlockCopyPasteDirective,
    BlockCopyPasteCutDirective,
    SendEmailComponent,
    CustomDropdownComponent
  ],
  exports: [NgxMaskModule],
  providers: [BsModalService],
})
export class SecureModule {}
