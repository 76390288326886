import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderStatusService {
  statusObj={
    contactInfo:'active',
    paymentInfo:'pending',
    summaryInfo:'pending',
    termsUse:'inactive',
    privacyPolicy:'inactive',
  };
  private headerStatus = new BehaviorSubject<HeaderStatus>(this.statusObj);
  public castHeaderStatus: Observable<object> = this.headerStatus.asObservable();
  constructor() { 
    //this is constructor
  }

  updateStatus(object){
    this.headerStatus.next(object); 
  }
}

export interface HeaderStatus {
  contactInfo: string,
  paymentInfo: string,
  summaryInfo:string,
  }