import { Component, OnInit } from '@angular/core';
import { HeaderStatusService } from 'src/app/common/services/header-status.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public showForms:string="contact_info";
  paymentInfoTitle = false;
  pagesStatus:any;
  
  constructor(private headerService: HeaderStatusService) { }

  ngOnInit(): void {
    this.headerService.castHeaderStatus.subscribe(data => {
      this.pagesStatus = data;
    });
  }

  getContactInfoData(data:any){
    this.showForms=data;
  }

  dynamicTitleChange(value){
    this.paymentInfoTitle = value;
  }

  updateStatus(data){
    this.headerService.updateStatus(data);
  }

}
