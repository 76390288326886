import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  errormsg: any;
  isPageNotFound = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
   // ngOnInit 
  }
}
