export class Exception {
  static exceptionMessage(error) {

    /* error message handling variable & storage */
    function USformatPhoneNumber(phone){
      let cleaned = ('' + phone).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      };
      return null
    }

    const dbName = (sessionStorage.getItem('navdbName')) ? decodeURIComponent(atob(sessionStorage.getItem('navdbName'))) : '';
    const merchantPhone = (sessionStorage.getItem('navmerchantPhone')) ? atob(sessionStorage.getItem('navmerchantPhone')) : '';
    const merchantEmail = (sessionStorage.getItem('navmerchantEmail')) ? decodeURIComponent(atob(sessionStorage.getItem('navmerchantEmail'))) : 'support@anddone.com';
    const allDetailsMsg = `We're sorry we are unable to process your payment at this time, please try back later or
    contact ${dbName} at ${USformatPhoneNumber(merchantPhone)} or ${merchantEmail} for assistance.`;
    const noPhoneMsg = `We're sorry we are unable to process your payment at this time, please try back later or
    contact ${dbName} at ${merchantEmail} for assistance.`;
    const noDetailsMsg = `We're sorry we are unable to process your payment at this time, please try back later or
    contact at ${merchantEmail} for assistance.`;

    const contactAdminErrorMsg = (dbName && merchantPhone && merchantEmail) ? allDetailsMsg :
                                 (dbName && !merchantPhone && merchantEmail) ? noPhoneMsg :
                                 noDetailsMsg;

    /* error message handling variable & storage */

    // let testmsg = [];
    // testmsg.push(contactAdminErrorMsg);
    // return testmsg;

    const errormessage = [];
    if (
      error !== undefined &&
      error !== null &&
      error.error !== undefined &&
      error.error !== null &&
      error.error.errors !== undefined &&
      error.error.errors !== null
    ) {
      const errorArray = error.error.errors;
      if (errorArray.length === 0) {
        errormessage.push(this.getExceptionMessage(error.error.message));
      } else {
        for (const i in errorArray) {
          if (i) {
            errormessage.push(this.getExceptionMessage(errorArray[i]['message']));
          }
        }
      }
      return errormessage;
    } else if (error.status === 401) {
       errormessage.push(this.getExceptionMessage('User is not authorized to access this resource'));
      return errormessage;
     } else if (error.status === 403 || error.status === 404 || error.status === 400) {
      if (error.error.errors === undefined) {
        if ('Message' in error.error === true) {
          errormessage.push(this.getExceptionMessage(error.error.Message));
        } else {
          errormessage.push(this.getExceptionMessage(error.error.message));
        }
        return errormessage;
      }
    } else if (error.status === 500) {
      // errormessage.push('Internal server error. Please contact administrator.');
      errormessage.push(contactAdminErrorMsg);
      return errormessage;
    }
    else if(typeof error == 'string' && error.startsWith('Key_')){
      errormessage.push(this.getExceptionMessage(error));
      return errormessage;
    }
    // errormessage.push('Something went wrong. Please contact administrator.');
    errormessage.push(contactAdminErrorMsg);
    return errormessage;
  }

  static getExceptionMessage(error) {
    let toastMessage = '';
    // error = error.charAt(0).toUpperCase() + error.slice(1);
    switch (error) {
      // Payment Form
      case 'Key_FormNameCannotBeEmpty':
        toastMessage = 'Form name cannot be empty';
        break;

      case 'CVC Declined':
        toastMessage = 'CVC Declined';
        break;

      case 'Key_Address1CannotBeEmpty':
        toastMessage = 'Address1 cannot be empty';
        break;

      case 'Key_CityRequired':
        toastMessage = 'City name is required';
        break;

      case 'Key_StateRequired':
        toastMessage = 'State name is required';
        break;

      case 'Key_PostalCodeCannotBeEmpty':
        toastMessage = 'Postal code is required';
        break;

      case 'Key_EmailCannotBeEmpty':
        toastMessage = 'Email id is required';
        break;

      case 'Key_CountryCannotBeEmpty':
        toastMessage = 'Country name is required';
        break;

      case 'Key_InvalidEmail':
        toastMessage = 'Email Id is invalid';
        break;

      case 'Key_MerchantInactive':
        toastMessage = 'Merchant is inactive';
        break;

      case 'Key_InactiveCustomerAccountId':
        toastMessage = 'Payment Account is Inactive';
        break;

      case 'Key_InActiveCustomer':
        toastMessage = 'Customer is Inactive';
        break;

      case 'Key_InActiveCustomerId':
        toastMessage = 'Customer is Inactive';
        break;

      case 'Key_InvalidFields':
        toastMessage = 'Invalid Fields';
        break;

      case 'Key_MerchantInActive':
        toastMessage = 'Merchant is inactive';
        break;

      case 'Key_InvalidColourCode':
        toastMessage = 'Colour code is invalid';
        break;

      case 'Key_InvalidAllowedCreditCardType':
        toastMessage = 'Credit Card type is invalid';
        break;

      case 'Key_AllowedCreditCardTypeCannotBeEmpty':
        toastMessage = 'Card Card type cannot be empty';
        break;

      case 'Key_AccountAlreadyExists':
        toastMessage = 'Account already exists';
        break;

      case 'Key_NoSuchProvisionMetadataForThisGatewayAndProcessor':
        toastMessage =
          'No such provision meta data for this gateway and processor';
        break;

      case 'Key_NoSuchChannelTypeForThisGatewayAndProcessor':
        toastMessage = 'No such channel type for this gateway and processor';
        break;

      case 'Key_NoSuchProcessorForThisGatewayAndChannelType':
        toastMessage = 'No such processor for this gateway and channel type';
        break;

      case 'Key_NoSuchProcessorForThisGateway':
        toastMessage = 'No such processor for this gateway';
        break;

      case 'Key_NoGatewaySupported':
        toastMessage = 'No gateway supported';
        break;

      case 'Key_InvalidGatewayName':
        toastMessage = 'Gateway name is invalid';
        break;

      case 'Key_InvalidProcessorName':
        toastMessage = 'Processor Name is invalid';
        break;

      case 'Key_InvalidChannelType':
        toastMessage = 'ChannelType is invalid';
        break;

      case 'Key_InvalidChannelSubType':
        toastMessage = 'ChannelSubType is invalid';
        break;

      case 'Key_InvalidUserName':
        toastMessage = 'User Name is invalid';
        break;

      case 'Key_InvalidPassword':
        toastMessage = 'Password is invalid';
        break;

      case 'Key_NoSuchCustomerAccount':
        toastMessage = 'Payment account does not exists';
        break;

      case 'Key_InvalidAccountType':
        toastMessage = 'Account type is invalid';
        break;

      case 'Key_InvalidAccountNumber':
        toastMessage = 'Account number is invalid';
        break;

      case 'Key_InvalidAccountNumber':
        toastMessage = 'Account number is invalid';
        break;

      case 'Key_InvalidRoutingNumber':
        toastMessage = 'Banking Routing number is invalid';
        break;

      case 'Key_InvalidRoutingNumber':
        toastMessage = 'Banking Routing number is invalid';
        break;

      case 'Key_CustomerNameInUse':
        toastMessage = 'Customer name is in use';
        break;

      case 'Key_CannotChangeAccountType':
        toastMessage = 'Cannot change account type';
        break;

      case 'Key_InvalidZipcode':
        toastMessage = 'Zipcode invalid.';
        break;

      case 'Key_InvalidCardExpiry':
        toastMessage = 'Card expiry date is invalid';
        break;

      case 'Key_InvalidCardExpiry':
        toastMessage = 'Card expiry date is invalid';
        break;

      case 'Key_InvalidCardNumber':
        toastMessage = 'Card number is invalid';
        break;

      case 'Key_InvalidCardNumber':
        toastMessage = 'Card number is invalid';
        break;

      case 'Key_InvalidCardType':
        toastMessage = 'Card type is invalid';
        break;

      case 'Key_InvalidCardType':
        toastMessage = 'Card type is invalid';
        break;

      case 'Key_FirstNameCannotBeEmpty':
        toastMessage = 'First name cannot be empty';
        break;

      case 'Key_LastNameCannotBeEmpty':
        toastMessage = 'Last name cannot be empty';
        break;

      case 'Key_PhoneCannotBeEmpty':
        toastMessage = 'Phone number cannot be empty';
        break;

      case 'Key_Address2CannotBeEmpty':
        toastMessage = 'Address 2 cannot be empty';
        break;

      case 'Key_InvalidURL':
        toastMessage = 'URL is invalid';
        break;

      case 'Key_SetMerchant':
        toastMessage = 'Merchant is not set';
        break;

      case 'Key_NoTransactionTypeSet':
        toastMessage = 'Transaction type is not set';
        break;

      case 'Key_NoProcessorsSet':
        toastMessage = 'Processor is not set';
        break;

      case 'Key_NoProcessorSetForEachChannelType':
        toastMessage = 'Processor is not set for each channel type';
        break;

      case 'Key_DuplicateChannelSubType':
        toastMessage = 'Duplicate channel sub type';
        break;

      case 'Key_InValidTransactionType':
        toastMessage = 'Transaction type is invalid';
        break;

      case 'Key_RoleIsInUsed':
        toastMessage = 'Role is in use';
        break;

      case 'Key_InvalidFirstName':
        toastMessage = 'First name is invalid';
        break;

      case 'Key_InvalidLastName':
        toastMessage = 'Last name is invalid';
        break;

      case 'Key_InvalidFaxNo':
        toastMessage = 'Fax number is invalid';
        break;

      case 'Key_InvalidCompanyName':
        toastMessage = 'Company name is invalid';
        break;

      case 'Key_InvalidPhoneNo':
        toastMessage = 'Phone number is invalid';
        break;

      case 'Key_InvalidCity':
        toastMessage = 'City name is invalid';
        break;

      case 'Key_InvalidPostalCode':
        toastMessage = 'Postal code is invalid';
        break;

      case 'Key_InvalidFederalTaxId':
        toastMessage = 'Federal Tax id is invalid';
        break;

      case 'Key_ResellerNameCannotBeEmpty':
        toastMessage = 'Reseller name cannot be empty';
        break;

      case 'Key_NameCannotBeEmpty':
        toastMessage = 'Name cannot be empty';
        break;

      case 'Key_CustomFeeNameAlreadyExists':
        toastMessage = 'Custom fee name already exists';
        break;

      case 'Key_InvalidFeeName':
        toastMessage = 'Fee name is invalid';
        break;

      case 'Key_InvalidFrequency':
        toastMessage = 'Frequency is invalid';
        break;

      case 'Key_InvalidChannelType':
        toastMessage = 'Channel Type is invalid';
        break;

      case 'Key_InvalidFeeType':
        toastMessage = 'Fee Type is invalid';
        break;

      case 'Key_InvalidFeeType':
        toastMessage = 'Fee Type is invalid';
        break;

      case 'Key_FeeCanNotBeDeletedAsIsItUsedInRatePlan':
        toastMessage = 'Fee cannot be deleted as it is used in rate plan';
        break;

      case 'Key_CanNotDeleteSystemFee':
        toastMessage = 'Cannot delete system fee';
        break;

      case 'Key_RatePlanIdInUse':
        toastMessage = 'Rate plan Id is in use';
        break;

      case 'Key_InvalidFeeId':
        toastMessage = 'Fee Id is invalid';
        break;

      case 'Key_InvalidUserType':
        toastMessage = 'User type is invalid';
        break;

      case 'Key_InvalidTargetId':
        toastMessage = 'Target Id is invalid';
        break;

      case 'Key_RatePlanCanNotBeDeletedAsAssignedResellerOrMerchantIsInActiveMode':
        toastMessage =
          'Rate plan cannot be deleted as it is assiged to a reseller or merchant';
        break;

      case 'Key_RatePlanAlreadyAssignedToTargetId':
        toastMessage = 'Rate plan already assigned to target Id';
        break;

      case 'Key_BuyRateCanNotBeNegative':
        toastMessage = 'Buy Rate cannot be negative';
        break;

      case 'Key_SellRateCanNotBeNegative':
        toastMessage = 'Sell Rate cannot be negative';
        break;

      case 'Key_InvalidFrequencyParameter':
        toastMessage = 'Frequency parameter is invalid';
        break;

      case 'Key_StartDateCannotBeEmpty':
        toastMessage = 'Start date cannot be empty';
        break;

      case 'Key_ResellerInActive':
        toastMessage = 'Reseller is inactive';
        break;

      case 'Key_InvalidMerchantId':
        toastMessage = 'Merchant Id is invalid';
        break;

      case 'Key_InvalidRatePlanId':
        toastMessage = 'Please select Rate plan';
        break;

      case 'Key_RatePlanNameCannotBeEmpty':
        toastMessage = 'Rate plan name cannot be empty';
        break;

      case 'Key_RatePlanNameIsInUse':
        toastMessage = 'Rate plan name is in use';
        break;

      case 'Key_ParentIdIsInvalid':
        toastMessage = 'Parent Id is invalid';
        break;

      case 'Key_DuplicateFeeConfig':
        toastMessage = 'Duplicate fee config';
        break;

      // Infrastructure
      case 'Key_CannotGoToEmulationMode':
        toastMessage = 'Cannot go to emulation mode';
        break;

      case 'Key_OldPasswordBlank':
        toastMessage = 'Old password is blank';
        break;

      case 'Key_InvalidOldPassword':
        toastMessage = 'Old Password is invalid';
        break;

      case 'Key_InvalidUsernameOrPassword':
        toastMessage = 'Invalid username or password';
        break;

      case 'Key_PasswordCannotBeEmpty':
        toastMessage = 'Password cannot be empty';
        break;

      case 'Key_InvalidPassword':
        toastMessage = 'Password is invalid';
        break;

      case 'Key_InvalidPasswordLength':
        toastMessage = 'Password length should be greater than 8 characters';
        break;

      case 'Key_MatchingPassword':
        toastMessage = 'Password does not meet history requirements.';
        break;

      case 'Key_LockedAccount':
        toastMessage = 'User is locked';
        break;

      case 'Key_InvalidEmailId':
        toastMessage = 'Email Id is invalid';
        break;

      case 'Key_UserNameCannotBeEmpty':
        toastMessage = 'User cannot be empty';
        break;

      case 'Key_UserMustBeAtleastOf6Characters':
        toastMessage = 'User name must be of atleast 6 charaters long';
        break;

      case 'Key_UserNameAlreadyExist':
        toastMessage = 'User name already exists';
        break;

      case 'Key_RoleNameCanNotBeEmpty':
        toastMessage = 'Role name cannot be empty';
        break;

      case 'Key_RoleNameIsAlreadyUsed':
        toastMessage = 'Role Name is already used';
        break;

      case 'Key_EmailNotExistsInDatabase':
        toastMessage = 'Email Id does not exists';
        break;

      case 'Key_NoRecordsFound':
        toastMessage = 'No record found';
        break;

      case 'Key_InActiveAccount':
        toastMessage = 'Account is inactive';
        break;

      case 'Key_UserEmailAlreadyExist':
        toastMessage = 'User Email already exists';
        break;

      case 'Key_AccountIdUsedWithContract':
        toastMessage = 'Account Id used with contract';
        break;
      case 'Key_NumbersNotAllowed':
        toastMessage = 'Numbers not allowed';
        break;
      case 'Key_InvalidModifyAccess':
        toastMessage = 'Modify access is invalid';
        break;

      case 'Key_InvalidDeleteAccess':
        toastMessage = 'Delete access is invalid';
        break;

      case 'Key_InvalidViewAccess':
        toastMessage = 'View access is invalid';
        break;

      case 'Key_InvalidExecuteAccess':
        toastMessage = 'Execute access is invalid';
        break;

      case 'Key_InvalidOperationid':
        toastMessage = 'Operation Id is invalid';
        break;

      case 'Key_OperartionCanNotBeEdited':
        toastMessage = 'Operation cannot be edited';
        break;

      case 'Key_OperartionCanNotBeDuplicate':
        toastMessage = 'Operation cannot be duplicate';
        break;

      case 'Key_InvalidRoleName':
        toastMessage = 'Role Name is invalid';
        break;

      case 'Key_InvalidRole':
        toastMessage = 'Role is invalid';
        break;

      case 'Key_InactiveParentReseller':
        toastMessage = 'Reseller is inactive.';
        break;

      case 'Key_TransactionDenied':
        toastMessage = 'Transaction Denied';
        break;

      case 'Key_InvalidChannelProvisionedData':
        toastMessage = 'Channel provision data is invalid';
        break;

      case 'Key_CannotRepeatOldTransaction':
        toastMessage = 'Cannot repeat old transaction';
        break;

      case 'Key_CannotRefundOldTransaction':
        toastMessage = 'Cannot refund old transaction';
        break;

      case 'Key_InvalidOperationType':
        toastMessage = 'Operation type is invalid';
        break;

      case 'Key_InvalidEBTCardExpiry':
        toastMessage = 'EBTCard expiry is invalid';
        break;

      case 'Key_CheckNumberMissing':
        toastMessage = 'Check number is missing';
        break;

      case 'Key_InvalidAmount':
        toastMessage = 'Amount is invalid';
        break;

      case 'Key_CardNumberMissing':
        toastMessage = 'Card number is missing';
        break;

      case 'Key_CardExpiryMissing':
        toastMessage = 'Card expiry date is missing';
        break;

      case 'Key_CardTypeMissing':
        toastMessage = 'Card type missing';
        break;

      case 'Key_InvalidTransactionCode':
        toastMessage = 'Transaction code is invalid';
        break;

      case 'Key_TenderInfoMissing':
        toastMessage = 'Tender info is missing';
        break;

      case 'Key_MerchantNotSpecified':
        toastMessage = 'Merchant is not specified';
        break;

      case 'Key_InvalidGatewayInfo':
        toastMessage = 'Gateway Info is invalid';
        break;

      case 'Key_InvalidProcessorInfo':
        toastMessage = 'Invalid processor configuration.';
        break;

      case 'Key_ChannelTypeUnknown':
        toastMessage = 'Channel Type unknown';
        break;

      case 'Key_MerchantDisabled':
        toastMessage = 'Merchant is disabled';
        break;

      case 'Key_CannotAdjustTransactionMerchantDisabled':
        toastMessage = 'Cannot adjust Transaction as merchant is disabled';
        break;

      case 'Key_DuplicateTransaction':
        toastMessage = 'Duplicate transaction';
        break;

      case 'Key_AdjustedAmountCannotbeLessThanZero':
        toastMessage = 'Adjusted amount cannot be less than zero';
        break;

      case 'Key_CannotAdjustTransactionMarchantMismatch':
        toastMessage = 'Cannot adjust Transaction Merchant mismatch';
        break;

      case 'Key_CannotAdjustTransactionNoTransactionResultFound':
        toastMessage = 'No transaction result found';
        break;

      case 'Key_CannotAdjustCancelledTransaction':
        toastMessage = 'Cannot adjust canceled transaction';
        break;

      case 'Key_CannotAdjustSettledTransaction':
        toastMessage = 'Cannot adjust settled transaction';
        break;

      case 'Key_CannotAdjustTransactionInvalidOperationType':
        toastMessage = 'Operation type is invalid';
        break;

      case 'Key_CannotCancelTransaction':
        toastMessage = 'Cannot cancel transaction';
        break;

      case 'Key_CannotRefundTransaction':
        toastMessage = 'Cannot refund transaction';
        break;

      case 'Key_CannotResubmitTransaction':
        toastMessage = 'Cannot resubmit transaction';
        break;

      case 'Key_OperationNotSupportedByProcessor':
        toastMessage = 'Operation not supported by processor';
        break;

      case 'Key_FraudTransaction':
        toastMessage = 'Fraud transaction';
        break;

      case 'Key_CVVFraudTransaction':
        toastMessage = 'CVV fraud transaction';
        break;

      case 'Key_AVSFraudTransaction':
        toastMessage = 'AVS fraud transaction';
        break;

      case 'Key_PinDataNotCaptured':
        toastMessage = 'Pin data not captured';
        break;

      case 'Key_CancellationFailed':
        toastMessage = 'Cancellation failed';
        break;

      case 'Key_InvalidPNRef':
        toastMessage = 'PNRef is invalid';
        break;

      case 'Key_InvalidAuthCode':
        toastMessage = 'AuthCode is invalid';
        break;

      case 'Key_InvalidTraceNumber':
        toastMessage = 'Trace number is invalid';
        break;

      case 'Key_InvalidMerchantId':
        toastMessage = 'Merchant Id is invalid';
        break;

      case 'Key_TransactionIsNotVerified':
        toastMessage = 'Transaction is not verified';
        break;

      case 'Key_MaximumAllowedRecordExceedsForBatchTransaction':
        toastMessage = 'Maximum allowed record exceeds for batch transaction';
        break;

      case 'Key_InvalidAuthCodeOrPNRef':
        toastMessage = 'Invalid AuthCode or PNRef';
        break;

      case 'Key_OriginalTransactionNotFound':
        toastMessage = 'Original transaction nor found';
        break;

      case 'Key_ResellerInActive':
        toastMessage = 'Reseller is inactive';
        break;

      case 'Key_NoACHDetailFound':
        toastMessage = 'ACH details not found';
        break;

      case 'Key_NoCCDetailFound':
        toastMessage = 'CC details not found';
        break;

      case 'Key_NoDCDetailFound':
        toastMessage = 'DC details not found';
        break;

      case 'Key_CvvDataMissing':
        toastMessage = 'Cvv data missing';
        break;

      case 'Key_ProcessorResponseError':
        toastMessage =
          "Transaction failed due to error in processor's response";
        break;

      case 'Key_NetworkError':
        toastMessage =
          "Transaction failed due to network issues at processor's end";
        break;

      case 'Key_ChannelConfigurationMissing':
        toastMessage =
          'Transaction failed due to invalid processor configuration';
        break;

      case 'Key_InvalidRequestData':
        toastMessage = 'Transaction failed due to invalid request data';
        break;

      case 'Key_AllowedTransactionTypeMissing':
        toastMessage = 'Allowed Transaction Types';
        break;

      case 'Key_ProcessorConfigurationMissing':
        toastMessage = 'Processor Configuration';
        break;

      case 'Key_BillingConfigurationMissing':
        toastMessage = 'Billing Configuration';
        break;

      case 'Key_ModuleConfigurationMissing':
        toastMessage = 'Feature Access';
        break;

      case 'Key_InactiveParentMerchant':
        toastMessage = 'Please contact your Reseller, to activate the account';
        break;

      case 'Key_TransactionOnHold':
        toastMessage = 'Transaction is on hold';
        break;

      case 'Key_TransactionDenied':
        toastMessage = 'Transaction is denied';
        break;

      case 'Key_TransactionFailed':
        toastMessage = 'Transaction failed';
        break;

      case 'Key_DailyTransactionAmountExceedsTheLimitForMerchant':
        toastMessage = 'Daily transaction amount exceeds the limit';
        break;

      case 'Key_WeeklyTransactionAmountExceedsTheLimitForMerchant':
        toastMessage = 'Weekly transaction amount exceeds the limit';
        break;

      case 'Key_MonthlyTransactionAmountExceedsTheLimitForMerchant':
        toastMessage = 'Monthly transaction amount exceeds the limit';
        break;

      case 'Key_MaximumTransactionAmountExceedsTheLimitForMerchant':
        toastMessage = 'Maximum transaction amount exceeds the limit';
        break;

      case 'Key_DailyTransactionCountExceedsTheLimitForMerchant':
        toastMessage = 'Daily transaction count exceeds the limit';
        break;

      case 'Key_WeeklyTransactionCountExceedsTheLimitForMerchant':
        toastMessage = 'Weekly transaction count exceeds the limit';
        break;

      case 'Key_MonthlyTransactionCountExceedsTheLimitForMerchant':
        toastMessage = 'Monthly transaction count exceeds the limit';
        break;

      case 'Key_DuplicateTransaction':
        toastMessage = 'Duplication transaction';
        break;

      case 'Key_YourTransactionIsOnHold':
        toastMessage = 'Your transaction is on hold';
        break;

      case 'Key_TransactionNotSucssessfulVelocityNotUpdated':
        toastMessage = 'Transaction not successful, Velocity not updated';
        break;

      case 'Key_CVVFraudTransaction':
        toastMessage = 'Cvv fraud transaction';
        break;

      case 'Key_AVSFraudTransaction':
        toastMessage = 'AVS fraud transaction';
        break;

      case 'Key_CountryOrIpAddressIsBlocked':
        toastMessage = 'Country or IP address is blocked';
        break;

      case 'Key_InvalidChannelType':
        toastMessage =
          'Channel type not configured in transaction types and processor configuration';
        break;

      case 'Key_InvalidChannelSubType':
        toastMessage =
          'Channel sub type not configured in transaction types and processor configuration ';
        break;

      // Merchant
      case 'Key_MerchantNameCannotBeEmpty':
        toastMessage = 'Merchant name can not be empty';
        break;

      case 'Key_MerchantAdminUserCannotBeEmpty':
        toastMessage = 'Merchant admin user name can not be empty';
        break;

      case 'Key_MerchantIdRequiredForRevenueCalMode':
        toastMessage = 'Merchant id required for revenue cal mode';
        break;

      case 'Key_InvalidChannelProvisionedData':
        toastMessage = 'Invalid provisioned data';
        break;

      case 'Key_ValueNotAvailable':
        toastMessage = 'Value not available';
        break;

      case 'Key_MerchantNameAlreadyExist':
        toastMessage = 'Merchant company already exists';
        break;

      case 'Key_InValidMerchantName':
        toastMessage = 'Invalid merchant name';
        break;

      case 'Key_InValidMerchantAdminUser':
        toastMessage = 'Invalid merchant admin user';
        break;

      case 'Key_InvalidPhone':
        toastMessage = 'Phone number is invalid';
        break;

      case 'Key_InvalidFax':
        toastMessage = 'Fax number is invalid';
        break;

      case 'Key_CustomerInactive':
        toastMessage = 'Customer is inactive';
        break;

      case 'Key_InvalidPaymentFormID':
        toastMessage = 'Invalid payment form id';
        break;

      case 'Key_CommunicationException':
        toastMessage = 'Communication exception';
        break;

      case 'Key_MerchantConfiguration':
        toastMessage = 'Merchant configuration';
        break;

      case 'Key_MerchantCannotBeDeleteBillingConfigAssigned':
        toastMessage =
          'Merchant can not be deleted since billing config is assigned';
        break;

      // Reseller

      case 'Key_ResellerAdminUserCannotBeEmpty':
        toastMessage = 'Reseller admin user can not be empty';
        break;

      case 'Key_ResellerNameInvalid':
        toastMessage = 'Invalid reseller name';
        break;

      case 'Key_FirstNameCannotBeEmpty':
        toastMessage = 'First name can not be empty';
        break;

      case 'Key_LastNameCannotBeEmpty':
        toastMessage = 'Last name can not be empty';
        break;

      case 'Key_EmailCannotBeEmpty':
        toastMessage = 'Email can not be empty';
        break;

      case 'Key_NoTransactionTypeSetForReseller':
        toastMessage = 'Transaction type is not set for reseller';
        break;

      case 'Key_DuplicateTransactionType':
        toastMessage = 'Duplicate transaction type';
        break;

      case 'Key_InvalidTransactionType':
        toastMessage = 'Invalid transaction type';
        break;

      case 'Key_InvalidProcessorName':
        toastMessage = 'Invalid processor name';
        break;

      case 'Key_InvalidGatewayName':
        toastMessage = 'Invalid gateway name';
        break;

      case 'Key_NoProcessorSetForReseller':
        toastMessage = 'No processor set for reseller';
        break;

      case 'Key_InvalidURL':
        toastMessage = 'Invalid URL';
        break;

      case 'Key_PostalCodeCannotBeEmpty':
        toastMessage = 'Postal code can not be empty';
        break;

      case 'Key_CityCannotBeEmpty':
        toastMessage = 'City can not be empty';
        break;

      case 'Key_Address1CannotBeEmpty':
        toastMessage = 'Address1 can not be empty';
        break;

      case 'Key_FederalTaxIdCannotBeEmpty':
        toastMessage = 'Federal tax id can not be empty';
        break;

      case 'Key_StateCannotBeEmpty':
        toastMessage = 'State can not be empty';
        break;

      case 'Key_InvalidIPAddress':
        toastMessage = 'Invalid IP address';
        break;

      case 'Key_ThemeCannotBeEmpty':
        toastMessage = 'Theme can not be empty';
        break;

      case 'Key_ResellerNameAlredyExist':
        toastMessage = 'Reseller company already exists';
        break;

      case 'Key_UserAlreadyExist':
        toastMessage = 'User name already exists';
        break;

      case 'Key_LogoCannotBeEmpty':
        toastMessage = 'Logo can not be empty';
        break;

      case 'Key_CannotDeleteIfChildExist':
        toastMessage = 'Can not delete since child exists';
        break;

      case 'Key_InvalidISVId':
        toastMessage = 'ISV id invalid.';
        break;

      case 'Key_TransactionOnHold_CountryIsBlocked':
        toastMessage = 'Transaction originating from this country is blocked.';
        break;

      case 'Key_TransactionDenied_CountryIsBlocked':
        toastMessage = 'Transaction originating from this country is blocked.';
        break;

      case 'Key_TransactionDenied_IPAddressIsBlocked':
      case 'Key_TransactionOnHold_IPAddressIsBlocked':
        toastMessage =
          'Transaction originating from this IP Address is blocked.';
        break;

      case 'Key_CanNotDoOfflineinCurrentStatusOfTransaction':
        toastMessage = 'Can not perform offline transaction in current status.';
        break;

      case 'Key_OfflineTransactionNotSupported':
        toastMessage = 'Offline transaction not supported.';
        break;

      case 'Key_CardNoNotMatchedWithOriginalTransaction':
        toastMessage = 'Card number does not match with original transaction.';
        break;

      // Added on 15-01-2018 as per list provided by back end team
      case 'key_FeeCanNotBeModifyAsIsItUsedInRatePlan':
        toastMessage = 'Fee can not be modify as it is used in rate plan.';
        break;

      case 'key_FeeCanNotBeDeleteAsIsItUsedInRatePlan':
        toastMessage = 'Fee can not be delete as it is used in rate plan.';
        break;

      case 'key_CanNotModifySystemFee':
        toastMessage = 'Can not modify system fee.';
        break;

      case 'key_CanNotModifyChannelType':
        toastMessage = 'Can not modify channel type.';
        break;

      case 'key_RatePlanIdInUse':
        toastMessage = 'Rate plan id in use.';
        break;

      case 'Key_InvalidAddress1':
        toastMessage = 'Invalid address1.';
        break;

      case 'Key_CustomerNameCannotBeBlank':
        toastMessage = 'Customer name cannot be blank.';
        break;

      case 'Key_CustomerFirstNameCannotBeBlank':
        toastMessage = 'Customer first name can not be blank.';
        break;

      case 'Key_CustomerLastNameCannotBeBlank':
        toastMessage = 'Customer last name can not be blank.';
        break;

      case 'Key_CustomerCompanyNameCannotBeBlank':
        toastMessage = 'Customer company name cannot be blank.';
        break;

      case 'Key_CustomerDayTimePhoneCannotBeBlank':
        toastMessage = 'Customer phone cannot be blank.';
        break;

      case 'Key_CustomerAddress1CannotBeBlank':
        toastMessage = 'Customer address1 cannot be blank.';
        break;

      case 'Key_CustomerCityCannotBeBlank':
        toastMessage = 'Customer city cannot be blank.';
        break;

      case 'Key_CustomerPostalCodeCannotBeBlank':
        toastMessage = 'Customer postal code cannot be blank.';
        break;

      case 'Key_InvalidAlternativePhoneNumber':
        toastMessage = 'Invalid alternative phone number.';
        break;

      case 'Key_InvalidMobileNumber':
        toastMessage = 'Invalid mobile number.';
        break;

      case 'Key_InvalidTitle':
        toastMessage = 'Invalid title.';
        break;

      case 'Key_CustomerEmailAlreadyExists':
        toastMessage = 'Customer email already exists.';
        break;

      case 'Key_InvalidCreditCardNumber':
        toastMessage = 'Invalid credit card number.';
        break;

      case 'Key_CreditCardNumberIsNotOfTypeVisa':
        toastMessage = 'Credit card number is not of type visa.';
        break;

      case 'Key_CreditCardNumberIsNotOfTypeMasterCard':
        toastMessage = 'Credit card number is not of type master card.';
        break;

      case 'Key_CreditCardNumberIsNotOfTypeAMEX':
        toastMessage = 'Credit card number is not of type amex.';
        break;

      case 'Key_CreditCardNumberIsNotOfTypeDiscover':
        toastMessage = 'Credit card number is not of type discover.';
        break;

      case 'Key_CreditCardNumberIsNotOfTypeDiners':
        toastMessage = 'Credit card number is not of type diners.';
        break;

      case 'Key_CreditCardNumberIsNotOfTypeJCB':
        toastMessage = 'Credit card number is not of type jcb.';
        break;

      case 'Key_InActiveMerchant':
        toastMessage = 'Merchant is inactive.';
        break;

      case 'Key_InValidBankName':
        toastMessage = 'Invalid bank name.';
        break;

      case 'Key_InValidInValidSSN':
        toastMessage = 'Invalid SSN.';
        break;

      case 'Key_AllowedTransactionTypeNotSet':
        toastMessage = 'Transaction Type not supported.';
        break;

      case 'Key_ChannelSubTypeNotSet':
        toastMessage = 'Channel sub type not supported.';
        break;

      case 'Key_AmountCanNotBeEmpty':
        toastMessage = 'Amount can not be empty.';
        break;

      case 'Key_NotificationTemplateIdCanNotBeEmpty':
        toastMessage = 'Notification template id can not be empty.';
        break;

      case 'Key_NotificationTemplateIdNumericOnly':
        toastMessage = 'Notification template id numeric only.';
        break;

      case 'Key_GracePeriodNumericOnly':
        toastMessage = 'Grace period numeric only.';
        break;

      case 'Key_ScheduleNameCanNotBeEmpty':
        toastMessage = 'Schedule name can not be empty.';
        break;

      case 'Key_InvalidScheduleName':
        toastMessage = 'Invalid schedule name.';
        break;

      case 'Key_InvalidScheduleEndDate':
        toastMessage = 'Invalid schedule end date.';
        break;

      case 'Key_InvalidResellerAdminUser':
        toastMessage = 'Invalid reseller admin user.';
        break;

      case 'key_InvalidCountryFailAction':
        toastMessage = 'Invalid country fail action.';
        break;

      case 'key_InvalidCountryFailAction':
        toastMessage = 'Invalid country fail action.';
        break;

      case 'key_InvalidIpAddressFailAction':
        toastMessage = 'Invalid IP address fail action.';
        break;

      case 'key_InvalidInvalidCountryCode':
        toastMessage = 'Invalid country code.';
        break;

      case 'Key_InvalidCountryId':
        toastMessage = 'Invalid country id.';
        break;

      case 'Key_InvalidCountryId':
        toastMessage = 'Invalid country id.';
        break;

      case 'Key_WeeklyDebitTransactionCountExceeded':
        toastMessage = 'Weekly debit transaction count exceeded.';
        break;

      case 'Key_WeeklyDebitTransactionAmountExceeded':
        toastMessage = 'Weekly debit transaction amount exceeded.';
        break;

      case 'Key_MonthlyDebitTransactionCountExceeded':
        toastMessage = 'Monthly debit transaction count exceeded.';
        break;

      case 'Key_MonthlyDebitTransactionAmountExceeded':
        toastMessage = 'Monthly debit transaction amount exceeded.';
        break;

      case 'Key_DailyDebitTransactionCountExceeded':
        toastMessage = 'Daily debit transaction count exceeded.';
        break;

      case 'Key_DailyDebitTransactionAmountExceeded':
        toastMessage = 'Daily debit transaction amount exceeded.';
        break;

      case 'Key_WeeklyCreditTransactionCountExceeded':
        toastMessage = 'Weekly credit transaction count exceeded.';
        break;

      case 'Key_WeeklyCreditTransactionAmountExceeded':
        toastMessage = 'Weekly credit transaction amount exceeded.';
        break;

      case 'Key_MonthlyCreditTransactionCountExceeded':
        toastMessage = 'Monthly credit transaction count exceeded.';
        break;

      case 'Key_MonthlyCreditTransactionAmountExceeded':
        toastMessage = 'Monthly credit transaction amount exceeded.';
        break;

      case 'Key_DailyCreditTransactionCountExceeded':
        toastMessage = 'Daily credit transaction count exceeded.';
        break;

      case 'Key_DailyCreditTransactionAmountExceeded':
        toastMessage = 'Daily credit transaction amount exceeded.';
        break;

      case 'Key_MaximumAmoutExceeded':
        toastMessage = 'Maximum amout exceeded.';
        break;

      case 'Key_WeeklyTransactionCountShouldNotBeNegative':
        toastMessage = 'Weekly transaction count should not be negative.';
        break;

      case 'Key_WeeklyTransactionAmountShouldNotBeNegative':
        toastMessage = 'Weekly transaction amount should not be negative.';
        break;

      case 'Key_MonthlyTransactionCountShouldNotBeNegative':
        toastMessage = 'Monthly transaction count should not be negative.';
        break;

      case 'Key_MonthlyTransactionAmountShouldNotBeNegative':
        toastMessage = 'Monthly transaction amount should not be negative.';
        break;

      case 'Key_DailyTransactionCountShouldNotBeNegative':
        toastMessage = 'Daily transaction count should not be negative.';
        break;

      case 'Key_DailyTransactionAmountShouldNotBeNegative':
        toastMessage = 'Daily transaction amount should not be negative.';
        break;

      case 'Key_MaximumAmoutShouldNotBeNegative':
        toastMessage = 'Maximum amout should not be negative.';
        break;

      // need to confirm with jaya
      case 'Key_DailyTransactionAmountExceedsTheLimitForGlobal':
      case 'Key_DailyTransactionAmountExceedsTheLimitForMerchant':
      case 'Key_DailyTransactionAmountExceedsTheLimitForUser':
      case 'Key_DailyTransactionAmountExceedsTheLimitForReseller':
        toastMessage = 'Daily transaction amount exceeds the limit.';
        break;

      case 'Key_WeeklyTransactionAmountExceedsTheLimitForGlobal':
      case 'Key_WeeklyTransactionAmountExceedsTheLimitForMerchant':
      case 'Key_WeeklyTransactionAmountExceedsTheLimitForUser':
      case 'Key_WeeklyTransactionAmountExceedsTheLimitForReseller':
        toastMessage = 'Weekly transaction amount exceeds the limit.';
        break;

      case 'Key_MonthlyTransactionAmountExceedsTheLimitForGlobal':
      case 'Key_MonthlyTransactionAmountExceedsTheLimitForMerchant':
      case 'Key_MonthlyTransactionAmountExceedsTheLimitForUser':
      case 'Key_MonthlyTransactionAmountExceedsTheLimitForReseller':
        toastMessage = 'Monthly transaction amount exceeds the limit.';
        break;

      case 'Key_MaximumTransactionAmountExceedsTheLimitForGlobal':
      case 'Key_MaximumTransactionAmountExceedsTheLimitForMerchant':
      case 'Key_MaximumTransactionAmountExceedsTheLimitForUser':
      case 'Key_MaximumTransactionAmountExceedsTheLimitForReseller':
        toastMessage = 'Maximum transaction amount exceeds the limit.';
        break;

      case 'Key_DailyTransactionCountExceedsTheLimitForGlobal':
      case 'Key_DailyTransactionCountExceedsTheLimitForMerchant':
      case 'Key_DailyTransactionCountExceedsTheLimitForUser':
      case 'Key_DailyTransactionCountExceedsTheLimitForReseller':
        toastMessage = 'Daily transaction count exceeds the limit.';
        break;

      case 'Key_WeeklyTransactionCountExceedsTheLimitForGlobal':
      case 'Key_WeeklyTransactionCountExceedsTheLimitForMerchant':
      case 'Key_WeeklyTransactionCountExceedsTheLimitForUser':
      case 'Key_WeeklyTransactionCountExceedsTheLimitForReseller':
        toastMessage = 'Weekly transaction count exceeds the limit.';
        break;

      case 'Key_MonthlyTransactionCountExceedsTheLimitForGlobal':
      case 'Key_MonthlyTransactionCountExceedsTheLimitForMerchant':
      case 'Key_MonthlyTransactionCountExceedsTheLimitForUser':
      case 'Key_MonthlyTransactionCountExceedsTheLimitForReseller':
        toastMessage = 'Monthly transaction count exceeds the limit.';
        break;

      case 'Key_TemplateNameCannotBeBlank':
        toastMessage = 'Template name can not be blank.';
        break;

      case 'Key_FromEmailCannotBeBlank':
        toastMessage = 'From email can not be blank.';
        break;

      case 'Key_ToEmailCannotBeBlank':
        toastMessage = 'To email can not be blank.';
        break;

      case 'Key_SubjectCannotBeBlank':
        toastMessage = 'Subject can not be blank.';
        break;

      case 'Key_SmtpServerNotConfigured':
        toastMessage = 'Smtp server not configured.';
        break;

      case 'Key_TemplateInUse':
        toastMessage = 'Template in use.';
        break;

      case 'Key_ReferenceCannotBeBlank':
        toastMessage = 'Reference can not be blank.';
        break;

      case 'key_ChannelTypeCannotBeBlank':
        toastMessage = 'Channel type can not be blank.';
        break;

      case 'key_InvalidFieldsSpecifiedInTemplate':
        toastMessage = 'Invalid fields specified in template.';
        break;

      case 'Key_InvalidTemplateName':
        toastMessage = 'Invalid template name.';
        break;

      case 'Key_InvalidTemplateOrCategoryId':
        toastMessage = 'Invalid template or category id.';
        break;

      case 'Key_InvalidFromEmail':
        toastMessage = 'Invalid from email.';
        break;

      case 'Key_InvalidToEmail':
        toastMessage = 'Invalid to email.';
        break;

      case 'Key_InvalidSubject':
        toastMessage = 'Invalid subject.';
        break;

      case 'Key_EMailshouldnotbenull':
        toastMessage = 'Email should not be blank.';
        break;

      case 'Key_EmailFormatisnotvalid':
        toastMessage = 'Email format is not valid.';
        break;

      case 'Key_EmailFormatisnotvalid':
        toastMessage = 'Email format is not valid.';
        break;

      case 'Key_InvalidAddressLine1':
        toastMessage = 'Invalid address line1.';
        break;

      case 'Key_InvalidProvisionedData':
        toastMessage = 'Invalid provisioned data.';
        break;

      case 'Key_InvalidEndDate':
        toastMessage = 'Invalid end date.';
        break;

      case 'Key_InvalidSubTotal':
        toastMessage = 'Invalid subtotal.';
        break;

      case 'Key_TotalNumberOfPaymentsCannotBeEmpty':
        toastMessage = 'Total number of payments can not be empty.';
        break;

      case 'Key_InvalidCustomerAccount':
        toastMessage = 'Invalid payment account.';
        break;

      case 'Key_AlreadyActivated':
        toastMessage = 'Already activated.';
        break;

      case 'Key_CannotAdjustTransactionNoResultFound':
        toastMessage = 'Can not adjust transaction no result found.';
        break;

      case 'Key_CannotAdjustCapturedTransaction':
        toastMessage = 'Can not adjust captured transaction.';
        break;

      case 'Key_RefundAmountCanNotExceedOriginalAmount':
        toastMessage = 'Refund amount can not exceed original amount.';
        break;

      case 'Key_TransactionCanNotBeAdjustedInCurrentStatus':
        toastMessage = 'Transaction can not be adjusted in current status.';
        break;

      case 'Key_BadRequest':
        toastMessage = 'Bad request.';
        break;

      case 'Key_CannotRefundCreditTransaction':
        toastMessage = 'Can not refund credit transaction.';
        break;

      case 'Key_CanRefundOnlySaleTransaction':
        toastMessage = 'You can refund only sale transaction.';
        break;

      case 'Key_InvalidPreAuthCode':
        toastMessage = 'Invalid pre auth code.';
        break;

      case 'Key_InvalidReferenceTransactionId':
        toastMessage = 'Invalid reference transaction id.';
        break;

      case 'Key_InvalidCVDataLength':
        toastMessage = 'Invalid CVV length.';
        break;

      case 'Key_InvalidInvoiceNo':
        toastMessage = 'Invalid invoice no.';
        break;

      // Added to handle StatusCode=404

      case 'Key_InvalidReseller':
        toastMessage = 'Invalid reseller.';
        break;

      case 'Key_InvalidCustomer':
        toastMessage = 'Invalid customer.';
        break;

      case 'Key_InvalidInvoice':
        toastMessage = 'Invalid invoice.';
        break;

      case 'Key_InvalidMerchant':
        toastMessage = 'Invalid merchant.';
        break;

      case 'Key_InvalidPaymentForm':
        toastMessage = 'Invalid payment form.';
        break;

      case 'Key_InvalidChannel':
        toastMessage = 'Invalid channel.';
        break;

      case 'Key_InvalidTransaction':
        toastMessage = 'Invalid transaction.';
        break;

      case 'Key_InValidRatePlan':
        toastMessage = 'Invalid rate plan.';
        break;

      case 'Key_InValidFee':
        toastMessage = 'Invalid fee.';
        break;

      case 'Key_InvalidUser':
        toastMessage = 'Invalid user.';
        break;

      case 'Key_InvalidVelocity':
        toastMessage = 'Invalid velocity.';
        break;

      case 'Key_InvalidCountries':
        toastMessage = 'Invalid countries.';
        break;

      case 'Key_UserNotFound':
        toastMessage = 'User not found.';
        break;

      case 'Key_RoleNotFound':
        toastMessage = 'Role not found.';
        break;

      case 'Key_OperationNotFound':
        toastMessage = 'Operation not found.';
        break;

      case 'Key_NotificationTemplateNotFound':
        toastMessage = 'Notification template not found.';
        break;

      case 'Key_InvalidNotificationTemplate':
        toastMessage = 'Invalid notification template.';
        break;

      case 'Key_NotificationCategoryNotFound':
        toastMessage = 'Notification category not found.';
        break;

      case 'Key_PaymentFormNotFound':
        toastMessage = 'Payment form not found.';
        break;

      case 'Key_FromAmountCantBeGreaterToAmount':
        toastMessage = 'Min amount can not be greater than max amount.';
        break;

      case 'Key_EnterNonNegativeAmount':
        toastMessage = 'Amount should be greater than zero.';
        break;

      case 'Key_InvalidCustomerList':
        toastMessage = 'Invalid customer list';
        break;

      case 'Key_InvalidCcSettlementTimeFormat':
        toastMessage = 'Invalid time format of cc settlement.';
        break;

      case 'Key_InvalidCcSettlementTime':
        toastMessage = 'Invalid cc settlement time.';
        break;

      case 'Key_Unauthorized':
        toastMessage = 'Unauthorized.';
        break;

      case 'Key_InvalidName':
        toastMessage = 'Invalid custom plan name.';
        break;

      case 'Key_InvalidProductsAndServices':
        toastMessage = 'Invalid products and services.';
        break;
      case 'Key_InvalidProducts':
        toastMessage = 'Invalid products.';
        break;

      case 'Key_InvalidFrequencyParam':
        toastMessage = 'Frequency parameter is invalid';
        break;

      case 'Key_InvalidTotalAmount':
        toastMessage = 'Total amount is invalid';
        break;

      case 'Key_InvalidNoOfPayments':
        toastMessage = 'Number of payments are invalid';
        break;

      case 'Key_InvalidInvalidPaymentAmount':
        toastMessage = 'Payment amount is invalid';
        break;

      case 'Key_CustomPalnNameAlreadyExists':
        toastMessage = 'Template plan name already exists.';
        break;

      case 'Key_EnterNonNegativeAmount':
        toastMessage = 'Please enter valid amount.';
        break;

      case 'Key_MerchantAdminUserMustBeMoreThan6Characters':
        toastMessage =
          'Merchant admin user name must be more than 6 characters.';
        break;

      case 'Key_ResellerAdminUserMustBeMoreThan6Characters':
        toastMessage =
          'Reseller admin user name must be more than 6 characters.';
        break;

      case 'Key_CannotCreateSubReseller':
        toastMessage = 'Can not create subreseller.';
        break;

      case 'Key_InvalidProduct':
        toastMessage = 'Product is not valid.';
        break;
      case 'Key_InvalidProductId':
        toastMessage = 'Product Id is not valid.';
        break;

      case 'Key_ProductNotFound':
        toastMessage = 'Product not found.';
        break;

      case 'Key_ProductNameCannotBeBlank':
        toastMessage = 'Product name can not be blank.';
        break;

      case 'Key_UnitPriceCannotBeBlank':
        toastMessage = 'Unit price can not be blank.';
        break;

      case 'Key_UnitPriceNotNumber':
        toastMessage = 'Please enter valid unit price.';
        break;

      case 'Key_UnitPriceShouldBePositiveNumber':
        toastMessage = 'Please enter valid unit price.';
        break;

      case 'Key_InvalidDiscountType':
        toastMessage = 'Discount type is not valid.';
        break;

      case 'Key_InvalidDiscount':
        toastMessage = 'Discount is not valid.';
        break;

      case 'Key_DiscountToBeGreaterThanZero':
        toastMessage = 'Please enter discount greater than 0.';
        break;

      case 'Key_DiscountNotNumber':
        toastMessage = 'Please enter valid discount.';
        break;

      case 'Key_UnitPriceNotToBeNegative':
        toastMessage = 'Unit price should not be negative.';
        break;

      case 'DiscountCantBeGreaterThanUnitPrice':
        toastMessage = 'Discount can not be greater than unit price.';
        break;

      case 'NameExists':
        toastMessage = 'Name already exists.';
        break;

      case 'Key_UnAuthorizedParent':
        toastMessage = 'Unauthorized Parent.';
        break;

      case 'Key_InvoiceAlreadyFinalized':
        toastMessage = 'Invoice already finalized.';
        break;

      case 'Key_InvalidInvoiceId':
        toastMessage = 'Invalid invoice id.';
        break;

      case 'Key_CustomerNotFound':
        toastMessage = 'Customer not found.';
        break;

      case 'Key_CustomerNotEditable':
        toastMessage = 'Customer not editable';
        break;

      case 'Key_InvoiceNotFound':
        toastMessage = 'Invoice not found.';
        break;
      case 'Key_InvalidInvoiceNumber':
        toastMessage = 'Invoice number is invalid.';
        break;

      case 'Key_CustomerInactive':
        toastMessage = 'Key_CustomerInactive.';
        break;

      case 'Key_ProductNotFound':
        toastMessage = 'Product not found.';
        break;

      case 'Key_UnitPriceNotNumber':
        toastMessage = 'Unit price not number.';
        break;

      case 'Key_DiscountTypeNotNumber':
        toastMessage = 'Discount type not number.';
        break;

      case 'Key_FinalAmountNotNumber':
        toastMessage = 'Final amount not number.';
        break;

      case 'Key_SubTotalNotNumber':
        toastMessage = 'SubTotal not number.';
        break;

      case 'Key_FinalAmountNotToBeNegative':
        toastMessage = 'Final amount should not be negative.';
        break;
      case 'Key_FinalInvoiceCannotBeDeleted':
        toastMessage = 'Final Invoice Cannot Be Deleted.';
        break;

      case 'Key_SubTotalNotToBeNegative':
        toastMessage = 'SubTotal should not be negative.';
        break;

      case 'Key_InvoiceDiscountNotToBeMoreThanProductTotal':
        toastMessage =
          'Discount Amount should not be greater than total amount.';
        break;
      case 'Key_PONumberCannotBeBlank':
        toastMessage = 'PO Number Cannot Be Blank.';
        break;

      case 'Key_DiscountNotNumber':
        toastMessage = 'Discount not number.';
        break;

      case 'Key_DiscountNotToBeNegative':
        toastMessage = 'Discount should not be negative.';
        break;
      case 'Key_DiscountCantBeGreaterThanToUnitPrice':
        toastMessage = 'Discount should not be greater than unit price.';
        break;
      case 'Key_DiscountTypeTobeEqualtoOneorTwo':
        toastMessage = 'Discount type to be equal to one or two.';
        break;
      case 'Key_FromDiscountCantBeGreaterToDiscount':
        toastMessage = 'From discount should not be greater than TO discount.';
        break;
      case 'Key_FromPriceShouldBePositiveNumber':
        toastMessage = 'From Price Should Be Positive Number.';
        break;
      case 'Key_ToDiscountShouldBePositiveNumber':
        toastMessage = 'To Discount Should Be Positive Number.';
        break;
      case 'Key_DiscountAndDiscountTypeNotToBeEmpty':
        toastMessage = 'Discount And DiscountType Should Not Be Empty.';
        break;
      case 'Key_ProductNameAlreadyExists':
        toastMessage = 'Product Name Already Exists.';
        break;
      case 'Key_DiscountCantBeGreaterThanHundered':
        toastMessage = 'Discount should not be greater than hundered.';
        break;

      case 'Key_DiscountCantBeGreaterThanSubTotal':
        toastMessage = 'Discount should not be greater than SubTotal.';
        break;
      case 'Key_ToEmailIdNotValid':
        toastMessage = 'Invalid To email.';
        break;

      case 'Key_CCEmailNotValid':
        toastMessage = 'Invalid CC email.';
        break;

      case 'Key_DueDateNotValid':
        toastMessage = 'Invalid Due date.';
        break;

      case 'Key_InvoiceDateNotValid':
        toastMessage = 'Invalid Invoice date.';
        break;

      case 'Key_CustomerIdNotValid':
        toastMessage = 'Invalid customer id.';
        break;

      case 'Key_CustomerNameNotString':
        toastMessage = 'Invalid Customer Name.';
        break;
      case 'Key_NameNotString':
        toastMessage = 'Name not in string.';
        break;

      case 'Key_TaxAmountNotNumber':
        toastMessage = 'TaxAmount should be number.';
        break;

      case 'Key_TaxAmountNotToBeNegative':
        toastMessage = 'TaxAmount should not be negative.';
        break;

      case 'Key_InvalidPaymentStatus':
        toastMessage = 'Invalid payment status.';
        break;
      case 'Key_InvalidInvoiceFormat':
        toastMessage = 'Invalid invoice format.';
        break;
      case 'Key_InvoiceDateNotFound':
        toastMessage = 'Invoice date not found.';
        break;
      case 'Key_InvoiceNumberNotFound':
        toastMessage = 'Invoice number not found.';
        break;
      case 'Key_InvalidCCEmailList':
        toastMessage = 'Invalid CC Email List.';
        break;
      case 'Key_InvalidToEmailList':
        toastMessage = 'Invalid TO Email List.';
        break;

      case 'Key_InvalidInvoiceStatus':
        toastMessage = 'Invalid invoice status.';
        break;

      case 'Key_InvalidDiscountType':
        toastMessage = 'Invalid discount type.';
        break;
      case 'Key_DueInDaysNotNumber':
        toastMessage = 'Due In Days Not Number.';
        break;
      case 'Key_DueInDaysNotToBeNegative':
        toastMessage = 'Due In Days Not To Be Negative.';
        break;

      case 'Key_InvalidDiscount':
        toastMessage = 'Invalid Discount.';
        break;

      case 'Key_PaymentFormIdNotValid':
        toastMessage = 'Invalid Payment Form Id.';
        break;

      case 'Key_PaymentFormNotValid':
        toastMessage = 'Invalid PaymentForm.';
        break;

      case 'Key_ProductNotToBeDuplicate':
        toastMessage = 'Duplicate products are not allowed.';
        break;

      case 'Key_ProductQuantityToBeGreaterThanZero':
        toastMessage = 'Product quantity should be greater than Zero.';
        break;

      case 'Key_ProductIdNotValid':
        toastMessage = 'Invalid ProductId.';
        break;

      case 'Key_DueDateTobeGreaterThanCurrentDate':
        toastMessage = 'DueDate should be greater than current date';
        break;

      case 'Key_FromInvoiceDateNotValid':
        toastMessage = 'Invalid From Date.';
        break;
      case 'Key_Invoice_IsDraft_Cannot_Be_Blank':
        toastMessage = 'Invoice IsDraft Cannot Be Blank.';
        break;
      case 'Key_Invoice_IsAutoInvoiceNumber_Cannot_Be_Blank':
        toastMessage = 'Invoice IsAutoInvoiceNumber Cannot Be Blank.';
        break;
      case 'Key_Invoice_IsAutoInvoiceDate_Cannot_Be_Blank':
        toastMessage = 'Invoice IsAutoInvoiceDate Cannot Be Blank.';
        break;

      case 'Key_ToInvoiceDateNotValid':
        toastMessage = 'Invalid To Date.';
        break;
      case 'Key_InvoiceAmountNotNumber':
        toastMessage = 'Invalid Invoice Amount.';
        break;

      case 'Key_InvalidPaymentStatusWhenInvoiceStatusisDraft':
        toastMessage = 'Invalid payment status when invoice status is draft.';
        break;

      case 'Key_InvoiceNotEditable':
        toastMessage = 'Invoice not allowed to edit.';
        break;

      case 'Key_FromInvoiceDateTobeGreaterThanToInvoiceDate':
        toastMessage = 'From Invoice Date To be Greater Than To Invoice Date';
        break;

      case 'Key_InvoiceUpdatedToFinal':
        toastMessage = 'Invoice Updated To Final';
        break;
      case 'Key_InvoiceCannotBeDeleted':
        toastMessage = 'Invoice Cannot Be Deleted';
        break;
      case 'Key_InvoiceIsDeleted':
        toastMessage = 'Invoice Is Deleted';
        break;
      case 'Key_FromInvoiceAmountNotNumber':
        toastMessage = 'From Invoice Amount Not Number';
        break;
      case 'Key_ToInvoiceAmountNotNumber':
        toastMessage = 'To Invoice Amount Not Number';
        break;
      case 'Key_ToInvoiceAmountToBeGreaterThanFromInvoiceAmount':
        toastMessage =
          'To Invoice Amount ToBe Greater Than From Invoice Amount';
        break;
      case 'Key_StartDateNotValid':
        toastMessage = 'Start Date Not Valid.';
        break;
      case 'Key_RecurringNameCannotBeEmpty':
        toastMessage = 'RecurringName Cannot Be Empty.';
        break;
      case 'Key_EndDateTobeGreaterThanCurrentDate':
        toastMessage = 'EndDate Tobe Greater Than CurrentDate.';
        break;
      case 'Key_EndDateNotValid':
        toastMessage = 'End Date Not Valid.';
        break;
      case 'Key_RecurringInvoiceisAlreadyActivated':
        toastMessage = 'Recurring Invoice is Already Activated.';
        break;
      case 'Key_RecurringInvoiceNotFound':
        toastMessage = 'Recurring Invoice Not Found.';
        break;
      case 'Key_DraftRecurringInvoiceCannotBeDeactivated':
        toastMessage = 'Draft Recurring Invoice Cannot Be Deactivated.';
        break;
      case 'Key_RecurringNameAlreadyExists':
        toastMessage = 'RecurringName Already Exists';
        break;
      case 'Key_IsActiveTobeEqualtoOneorTwo':
        toastMessage = 'IsActive Tobe Equal to OneorTwo';
        break;
      case 'Key_FrequencyTobeInRangeofZeroToFour':
        toastMessage = 'Frequency Tobe InRange of Zero To Four';
        break;
      case 'Key_InvoiceNotToBeActivatedWhenInvoiceStatusIsDraft':
        toastMessage =
          'Invoice Not ToBe Activated When Invoice Status Is Draft';
        break;
      case 'Key_InvoiceisActivated':
        toastMessage = 'Invoice is Activated.';
        break;
      case 'Key_InvoiceCannotBeActivated':
        toastMessage = 'Invoice CannotBe Activated.';
        break;
      case 'Key_DraftInvoiceCannotBeActivated':
        toastMessage = 'Draft Invoice CannotBe Activated.';
        break;
      case 'Key_InvoiceNotToBeDeActivatedWhenInvoiceStatusIsDraft':
        toastMessage =
          'Invoice Not ToBe DeActivated When InvoiceStatus Is Draft.';
        break;
      case 'Key_InvoiceisDeActivated':
        toastMessage = 'Invoice is DeActivated.';
        break;
      case 'Key_InvoiceSentSuccessfully':
        toastMessage = 'Invoice Sent Successfully.';
        break;
      case 'Key_InvoiceCannotBeDeactivated':
        toastMessage = 'Invoice Cannot Be Deactivated';
        break;
      case 'Key_DraftInvoiceCannotBeDeactivated':
        toastMessage = 'Draft Invoice CannotBe Deactivated';
        break;
      // case 'Key_InvalidFrequencyParameter':
      //   toastMessage = 'Invalid Frequency Parameter';
      //   break;
      case 'Key_PaymentTermNotString':
        toastMessage = 'Payment Term Not String';
        break;
      case 'Key_PaymentTermExceededMaximumLength':
        toastMessage = 'PaymentTermExceededMaximumLength';
        break;
      case 'Key_PaymentTermCannotBeBlank':
        toastMessage = 'PaymentTermCannotBeBlank';
        break;
      case 'Key_UnAuthorizedAccess':
        toastMessage = 'Unauthorized to access this resource.';
        break;
      case 'Key_TransactionCanNotBeUpdated':
        toastMessage = 'Unable to map transaction to customer.';
        break;

      // IVS Exception
      case 'Key_ISVNameAlreadyExists':
        toastMessage = 'ISV name already exists.';
        break;
      case 'Key_ISVNameNotString':
        toastMessage = 'ISV name invalid.';
        break;
      case 'Key_ToEmailNotValid':
        toastMessage = 'Email address invalid.';
        break;
      case 'Key_PhoneNumberShouldBe10Digits':
        toastMessage = 'Phone number should be 10 digit.';
        break;
      case 'Key_ISVAddress1NotString':
        toastMessage = 'Address 1 invalid.';
        break;
      case 'Key_ISVAddress2NotString':
        toastMessage = 'Address 2 invalid.';
        break;
      case 'Key_ISVCityNotString':
        toastMessage = 'City invalid.';
        break;
      case 'Key_ISVStateNotString':
        toastMessage = 'State invalid.';
        break;
      case 'Key_ISVCountryNotValidRange':
        toastMessage = 'Country invalid.';
        break;
      case 'Key_isDeletedNotInRange':
        toastMessage = 'Deleted not in range.';
        break;
      case 'Key_ToCreatedOnDateNotValid':
        toastMessage = 'Create date invalid.';
        break;
      case 'Key_ToModifiedOnDateNotValid':
        toastMessage = 'Modified date invalid.';
        break;
      case 'Key_ModifiedByNotString':
        toastMessage = 'Modified date invalid.';
        break;
      case 'Key_CreatedByNotString':
        toastMessage = 'Modified date invalid.';
        break;
      case 'Key_ISVZipCodeFormatNotValid':
        toastMessage = 'Zip code format invalid.';
        break;
      case 'Key_ISVZipCodeNotString':
        toastMessage = 'Zip code invalid.';
        break;
      case 'key_ISVNotFound':
        toastMessage = 'ISV not found.';
        break;
      case 'Key_ISVKeyNotString':
        toastMessage = 'ISV key invalid.';
        break;
      case 'Key_IsvKeyNotFound':
        toastMessage = 'ISV key not found.';
        break;
      case 'Key_PhoneNumberNotCorrect':
        toastMessage = 'Phone number invalid.';
        break;
      case 'key_ZipCodeNotValid':
        toastMessage = 'Zip code invalid.';
        break;
      case 'Key_ISVKeyNotGenerated':
        toastMessage = 'ISV Key Not Generated.';
        break;
      case 'Key_ISVIndustryNotValidRange':
        toastMessage = 'ISV Industry Not Valid Range.';
        break;
      case 'Key_CountryNotValid':
        toastMessage = 'Country invalid.';
        break;
      case 'key_IndustryNotValid':
        toastMessage = 'Industry invalid.';
        break;
      case 'Key_ISVEmailAlreadyExists':
        toastMessage = 'ISV Email Already Exists.';
        break;
      case 'Key_InvalidCustomerIdOrCustomerAccountId':
        toastMessage = 'Unable to save customer details.';
        break;
      // Partner
      case ' Key_PartnerNameInUse':
        toastMessage = 'Partner name already exists.';
        break;
      case 'Key_PartnerNameCannotBeBlank':
        toastMessage = 'Partner name cannot be blank.';
        break;
      case 'Key_PartnerTypeNotInRange':
        toastMessage = 'Partner type not in range.';
        break;
      case 'Key_InvalidPhone':
        toastMessage = 'Phone number invaild.';
        break;
      case 'Key_InvalidAddress1':
        toastMessage = 'Address line 1 invalid.';
        break;
      case 'Key_InvalidEmail':
        toastMessage = 'Email imvalid.';
        break;
      case 'Key_PartnerStatusNotInRange':
        toastMessage = 'Partner status not in range.';
        break;
      case 'Key_PartnerEmailIsNotPresent':
        toastMessage = 'Partner email is not present.';
        break;
      case 'Key_PartnerEmailInUse':
        toastMessage = 'Partner email already use.';
        break;
      case 'Key_PartnerIsAlreadyActive':
        toastMessage = 'Partner already active.';
        break;
      case 'Key_PartnerIsActivated':
        toastMessage = 'Partner is activated.';
        break;
      case 'Key_PartnerIsAlreadyInActive':
        toastMessage = 'Partner is already inactive.';
        break;
      case 'Key_PartnerIsInActivated':
        toastMessage = 'Partner is inactivated.';
        break;
      case 'Key_NotFoundPartnerForMerchant':
        toastMessage = 'Partner for merchant not found.';
        break;
      case 'Key_InvalidLogoFormat':
        toastMessage = 'Logo formant invalid.';
        break;
      case 'Key_FileSizeCanNotBeMoreThan5MB':
        toastMessage = 'File size cannot be more than 5MB.';
        break;
      case 'Key_PartnerIsNotPresent':
        toastMessage = 'Partner is not present.';
        break;
      case 'Key_LogoUploadedSuccessFully':
        toastMessage = 'Logo uploaded successfully.';
        break;
      case 'Key_RemovedPartnerLogo':
        toastMessage = 'Partner logo removed.';
        break;
      case 'Key_LogoIsNotPresentToremove':
        toastMessage = 'Logo is not present to remove.';
        break;
      case 'Key_DebitCardAccountNotSupported':
        toastMessage = 'Debit card account not supported.';
        break;
      case 'Key_InvalidPartner':
        toastMessage = 'Invalid Partner.';
        break;

      case 'Key_ZipCodeCanNotBlankOrNull':
        toastMessage = 'Postal zip code can not be blank.';
        break;
      case 'Key_WebsiteURLCanNotBlankOrNull':
        toastMessage = 'Website URL can not be blank.';
        break;
      case 'Key_InvalidZipCode':
        toastMessage = 'Invalid postal zip code.';
        break;
      case 'Key_InvalidDepositPercentWithoutPlan':
        toastMessage = 'Invalid deposit % without plan.';
        break;
      case 'Key_PartnerCompanyNameInUse':
        toastMessage = 'Partner company name alreday in use.';
        break;
      case 'Key_PartnerCompantNameCannotBeBlank':
        toastMessage = 'Partner company name can not be blank.';
        break;
      case 'Key_IndustryNotValide':
        toastMessage = 'Invalid industry type.';
        break;
      case 'Key_InvalidDepositPercentWithPlan':
        toastMessage = 'Invalid deposit % with plan.';
        break;
      case 'Key_SupportEmailIsNotBlankOrNull':
        toastMessage = 'Support email can not be blank.';
        break;
      case 'Key_FirstNameCanNotBlankOrNull':
        toastMessage = 'First name can not be blank.';
        break;
      case 'Key_LastNameCanNotBlankOrNull':
        toastMessage = 'Last name can not be blank.';
        break;
      case 'Key_InvalidLastName':
        toastMessage = 'Invalid last name.';
        break;
      case 'Key_AddressLine1CanNotBlankOrNull':
        toastMessage = 'Address line 1 can not be empty.';
        break;
      case 'Key_InvalidSupportEmail':
        toastMessage = 'Invalid support.';
        break;
      case 'Key_CountryCodeNotValide':
        toastMessage = 'Invalid country code.';
        break;
      case 'Key_CityCanNotBlankOrNull':
        toastMessage = 'City can not be blank.';
        break;
      case 'Key_InvalidState':
        toastMessage = 'Invalid state.';
        break;
      case 'Key_InvalidWebsiteURL':
        toastMessage = 'Invalid Website url.';
        break;
      case 'Key_InvalidFirstName':
        toastMessage = 'Invalid first name.';
        break;
      case 'Key_InvalidCity':
        toastMessage = 'Invalid city.';
        break;
      case 'Key_StateCanNotBlankOrNull':
        toastMessage = 'State can not be blank.';
        break;
      case 'Key_PartnerDetailsChangedSuccessfully':
        toastMessage = 'Partner details changes successfully.';
        break;
      case 'Key_PartnerDetailsCouldNotBeSaved':
        toastMessage = 'Partner details can not be saved.';
        break;
      case 'Key_PartnerLogoNotPresent':
        toastMessage = 'Partnert logo not present.';
        break;

      case 'Key_PartnerIdNotValid':
        toastMessage = 'Invalid Partner Id.';
        break;
      case 'Key_OrderIdNotValid':
        toastMessage = 'Invalid Order Id';
        break;
      case 'Key_OrderCodeNotValidate':
        toastMessage = 'Invalid Order Code.';
        break;
      case 'Key_PartnerIdsNotString':
        toastMessage = 'PartnerIds cannot be String.';
        break;
      case 'Key_PartnerInActive':
        toastMessage = 'Partner InActive.';
        break;
      case 'Key_InterestNotToBeNegative':
        toastMessage = 'Interest cannot be negative.';
        break;
      case 'Key_InterestAmountNotNumber':
        toastMessage = 'Interest amount cannot be number.';
        break;
      case 'Key_ServiceFeeAmountNotNumber':
        toastMessage = 'Service Fee Amount cannot be Number.';
        break;
      case 'Key_ServiceFeeNotToBeNegative':
        toastMessage = 'Key_Service fee cannot be negative';
        break;
      case 'Key_FrequencyTobeInRangeofZeroToSix':
        toastMessage = 'Key_Frequency between 0 to 6.';
        break;
      case 'Key_FrequencyParamNotInRange':
        toastMessage = 'Frequency param not InRange.';
        break;
      case 'Key_DiscountCantBeGreatThanSubTotal':
        toastMessage = 'Discount cantnot be great than subTotal.';
        break;
      case 'Key_InvalidPhoneNumber':
        toastMessage = 'Invalid Phone Number.';
        break;
      case 'Key_FirstNameNotString':
        toastMessage = 'FirstName cannot be String.';
        break;
      case 'Key_LastNameNotString':
        toastMessage = 'LastName cannot be String';
        break;
      case 'Key_PhoneNotString':
        toastMessage = 'Phone number cannot be String';
        break;
      case 'Key_PhoneNumberShouldBe10Digits':
        toastMessage = 'Phone number should be of 10 digits only.';
        break;
      case 'Key_EmailNotString':
        toastMessage = 'Email cannot be String.';
        break;
      case 'Key_ZipCodeNotValid':
        toastMessage = 'Invalid Zip code.';
        break;
      case 'Key_DescriptionNotString':
        toastMessage = 'Description cannot be String.';
        break;
      case 'Key_OrderStateNotString':
        toastMessage = 'Order cannot be String.';
        break;
      case 'Key_TokenExpired':
        toastMessage = 'Token Expired.';
        break;
      case 'Key_InvalidToken':
        toastMessage = 'Invalid Token.';
        break;
      case 'Key_InvalidDueDays':
        toastMessage = 'Invalid due days.';
        break;
      case 'key_InvalidNoOfPayments':
        toastMessage = 'Number of payments are invalid';
        break;
      case 'Key_OrderNotFoun':
        toastMessage = 'Order not found.';
        break;

      case 'Key_FeeConfigurationNotFound':
        toastMessage = 'Fee configuration not found.';
        break;
      case 'Key_FeeConfigurationParamsConNotNull':
        toastMessage = 'FeeConfiguration cannot be null.';
        break;
      case 'Key_OrderAmountNotNumber':
        toastMessage = 'Invaild Order Amount.';
        break;
      case 'Key_OrderCodeNotString':
        toastMessage = 'Invaild Order Code.';
        break;
      case 'Key_OrderNotFound':
        toastMessage = 'Order Not Found';
        break;
      case 'Key_InvalidOrderStatus':
        toastMessage = 'Invaild order status.';
        break;
      case 'Key_FromOrderDateNotValid':
        toastMessage = 'Invaild From Order Date.';
        break;
      case 'Key_ToOrderDateNotValid':
        toastMessage = 'Invaild To Order Date.';
        break;
      case 'Key_FromOrderDateTobeGreaterThanToOrderDate':
        toastMessage = 'Invaild Date.';
        break;
      case 'Key_ToOrderAmountToBeGreaterThanFromOrderAmount':
        toastMessage = 'Invaild Amount.';
        break;
      case 'Key_FromOrderAmountNotNumber':
        toastMessage = 'From order amount is not a number.';
        break;
      case 'Key_ToOrderAmountNotNumber':
        toastMessage = 'To order amount is not a number.';
        break;
      case 'Key_TransactionAmountNotNumber':
        toastMessage = 'Transaction amount is not a number.';
        break;
      case 'Key_SubTotalNotNumber':
        toastMessage = 'SubTotal is not a number.';
        break;
      case 'Key_NumberOfPaymentsNotNumber':
        toastMessage = 'Number of payments is not a number.';
        break;
      case 'Key_NumberOfPaymentsNotToBeNegative':
        toastMessage = 'Number of payments can not be negative.';
        break;
      case 'Key_FirstTransactionDateNotValid':
        toastMessage = 'First transaction date  is Invalid.';
        break;
      case 'Key_FirstTransactionDateTobeGreaterThanCurrentDate':
        toastMessage =
          'First transaction date must be greater than current date.';
        break;
      case 'Key_CardNumberNotString':
        toastMessage = 'Card number cannot be string.';
        break;
      case 'Key_CardExpiryNotString':
        toastMessage = 'Card expiry cannot be string.';
        break;
      case 'Key_CardHolderNameNotString':
        toastMessage = 'Card holder cannot be string.';
        break;
      case 'Key_OrderAlreadyProcessed':
        toastMessage = 'Order already processed.';
        break;
      case 'Key_CardTypeNotString':
        toastMessage = 'Card type cannot be string.';
        break;
      case 'Key_CVVIsInvalid':
        toastMessage = 'Invalid CVV.';
        break;
      case 'Key_SameAsCustomerAddressNotBoolean':
        toastMessage = 'Invalid customer address.';
        break;
      case 'Key_PaymentPlanNotBoolean':
        toastMessage = 'Invalid Payment Plan.';
        break;
      case 'Key_Address1NotString':
        toastMessage = 'Address1 cannot be string.';
        break;
      case 'Key_Address2NotString':
        toastMessage = 'Address2 cannot be string.';
        break;
      case 'Key_CityNotString':
        toastMessage = 'City cannot be string.';
        break;
      case 'Key_StateNotString':
        toastMessage = 'State cannot be string.';
        break;
      case 'Key_CountryNotValidRange':
        toastMessage = 'Invalid Country.';
        break;
      case 'Key_ZipCodeNotString':
        toastMessage = 'Zip code cannot be string.';
        break;
      case 'Key_ZipCodeFormatNotValid':
        toastMessage = 'Invalid zip code.';
        break;
      case 'Key_InvalidAcceptTerms':
        toastMessage = 'Invalid accept Terms';
        break;
      case 'Key_CancelledTransaction':
        toastMessage = 'Canceled Transaction.';
        break;

      case 'Key_ProductExpiryDateNotValid':
        toastMessage = 'Invalid Product Expiry Date.';
        break;
      case 'Key_FromActiveUntilDateNotValid':
        toastMessage = 'Invalid From Active Until Date.';
        break;
      case 'Key_ToActiveUntilDateNotValid':
        toastMessage = 'Invalid To Active Until Date.';
        break;
      case 'Key_ProductExpiryDateNotString':
        toastMessage = 'Invalid Product Expiry Date';
        break;
      case 'Key_InvoiceNumberAlreadyExists':
        toastMessage = 'Invoice Number already exist.';
        break;
      case 'Key_InvoiceAlreadyCancelled':
        toastMessage = 'Invoice is already canceled.';
        break;
      case 'Key_CancelledInvoiceCanNotBeEdit':
        toastMessage = 'Can not edit canceled invoice.';
        break;
      case 'Key_CancelledInvoiceCanNotBeEdit':
        toastMessage = 'Can not edit canceled invoice.';
        break;
      case 'Key_InvoiceCancelledSuccessfully':
        toastMessage = 'Invoice canceled successfully.';
        break;
      case 'Key_InvoiceCanNotBeSend':
        toastMessage = 'Send invoice failed.';
        break;
      case 'Key_InvoiceAlreadyFullfilled':
        toastMessage = 'Invoice is already marked as fullfilled.';
        break;
      case 'Key_InvoiceCannotBeFullfiled':
        toastMessage = 'Invoice can not be marked as fullfilled.';
        break;
      case 'Key_ProductSourceCannotBeBlank':
        toastMessage = 'Product source cannot be blank';
        break;
      case 'Key_InvalidLineItemSource':
        toastMessage = 'Invalid line item source';
        break;

      case 'Key_InvoiceOfflinePaymentNotFound':
        toastMessage = 'Invoice offline payment not found.';
        break;
      case 'Key_CustomFieldsNotFound':
        toastMessage = 'Custom fields not found';
        break;
      case 'Key_BankNameNotString':
        toastMessage = 'Invalid Bank Name.';
        break;
      case 'Key_InvalidRoutingLength':
      case 'Key_RoutingNotString':
        toastMessage = 'Invalid routing';
        break;
      case 'Key_InvalidAccountType':
        toastMessage = 'Invalid account type';
        break;
      case 'Key_InvalidCheckType':
        toastMessage = 'Invalid check type';
        break;
      case 'Key_AccountNumberNotString':
        toastMessage = 'Invalid account number';
        break;
      case 'Key_CheckNumberNotString':
        toastMessage = 'Invalid check number';
        break;
      case 'Key_CustomFieldAlreadyExists':
        toastMessage = 'Custom field already exists';
        break;
      case 'DefaultAmount':
        toastMessage = 'Minimum amount shoud be one';
        break;
      case 'Key_TextFieldValueShouldBeLessThanMaxLength':
      case 'Key_TextFieldValueShouldNotBeGreaterThanMinLength':
        toastMessage =
          'Default text length should be in between MinLength and MaxLength';
        break;

      case 'Key_TransactionInProcess':
        toastMessage =
          'Transaction under processing - Order cannot be canceled/modified. Try again later.';
        break;
      case 'Key_PastHuntDate':
        toastMessage = 'Adventure date passed - Order cannot be canceled.';
        break;
      case 'Key_InvoiceTransactionNotFound':
        toastMessage = 'Invoice transaction not found.';
        break;
      case 'Key_TransactionAmountNotValid':
        toastMessage = 'Transaction amount is not valid.';
        break;
      case 'Key_TransactionAlreadyRecorded':
        toastMessage = 'Transaction already recorded.';
        break;
      case 'Key_RefundIsGreterThanMaxRefund':
        toastMessage =
          'Modified refund amount should not be greater than paid amount.';
        break;
      case 'Key_RefundIsLessThanMaxRefund':
        toastMessage =
          'Modified refund amount should not be less than default refund amount.';
        break;
      case 'key_CanNotDeleteDefaultConfiguration':
        toastMessage = 'Can not delete Active setting';
        break;
      case 'Key_InvalidTransacitonStatusToReturn':
        toastMessage = 'Invalid Transaction status';
        break;
      case 'Key_VoidAmountCanNotExceedOriginalAmount':
        toastMessage = 'Void amount cannot exceed Original amount';
        break;
      case 'Key_VoidAmountCanNotBeLessThanOriginalAmount':
        toastMessage = 'Void amount cannot be less than Original amount';
        break;
      // Add new exception keys above this line
      case 'User is not authorized to access this resource':
        toastMessage = 'User is not authorized to access this resource.';
        break;
      case 'Key_ProfileAliasNotFound':
        toastMessage = 'Profile Alias Not Found';
        break;
      case 'Key_SwipeDeviceNotFound':
        toastMessage = 'Swipe Device Not Found';
        break;
      case 'Key_SwipeDeviceModelNotFound':
        toastMessage = 'Swipe Device Model Not Found';
        break;
      case 'Key_InvalidTax':
        toastMessage = 'Tax is not allowed for tax exempt customer.';
        break;
      case 'Key_ACHVoidNotSupported':
        toastMessage =
          'Void is not supported by this processor. Please Refund the transaction after it is settled.';
        break;
      case 'Key_InvalidPostalCode':
        toastMessage = 'Postal code is invalid';
        break;
      case 'Key_PaymentAcceptanceLimitExceed':
        toastMessage = 'Transaction Amount exceeds the limit, please use an alternate payment method';
        break;
        default:
          toastMessage = error
            .replace(/([A-Z]+)/g, ' $1')
            .replace(/([A-Z][a-z])/g, ' $1')
            .replace('Key_', ' ');
      }
    return toastMessage;
  }
}
