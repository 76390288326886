import { Component, OnInit } from '@angular/core';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { StorageService } from 'src/app/common/services/storage.service';
import { HeaderStatusService } from '../../../common/services/header-status.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  pagesStatus: any;
  settings: any;
  logoUrl: string;
  isLogoUsedAsBusinessName: boolean = false;
  bussinessName: any;
  constructor(
    private headerService: HeaderStatusService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    let sessionData = this.storageService.get(
      StorageType.session,
      'agentSettings'
    );
    this.settings = JSON.parse(sessionData || '{ }');
    this.isLogoUsedAsBusinessName =
      this.settings?.merchantBranding?.isLogoUsedAsBusinessName;
    this.bussinessName = this.settings?.merchantBranding?.businessName?.trim();
    this.logoUrl = this.settings?.logo;
    this.headerService.castHeaderStatus.subscribe((data) => {
      this.pagesStatus = data;
    });
  }

  updateStatus(data) {
    this.headerService.updateStatus(data);
  }
}
