import {
  NgForm,
  UntypedFormGroup,
  UntypedFormControl,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Messages } from './messages.constant';

interface Config {
  [key: string]: {
    [key: string]: { [key: string]: string };
  };
}

export class Validator {
  config: Config;
  formErrors: {};

  constructor(config: Config) {
    this.config = config;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  validate(ngForm: NgForm | FormGroup) {
    this.formErrors = {};
    // Validate multilevel form's  validation (Used recursive)
    this.findChildrenAndValidate(ngForm, '');
    return this.formErrors;
  }

  private findChildrenAndValidate(obj, fieldName) {
    if (typeof obj.controls !== 'undefined') {
      const newObj = Object.keys(obj.controls);
      for (const i in newObj) {
        if (i) {
          this.findChildrenAndValidate(obj.controls[newObj[i]], newObj[i]);
        }
      }
    } else {
      if (obj && obj.dirty && !obj.valid && obj.errors !== null) {
        Object.keys(obj.errors).forEach((ek) => {
          this.formErrors[fieldName] =
            this.replacedToArgs(Messages[ek], this.config[fieldName][ek]) + ' ';
        });
      }
    }
  }

  private replacedToArgs(message: string, args: { [key: string]: string }) {
    if (args !== undefined) {
      Object.keys(args).forEach((arg) => {
        message = message.replace(new RegExp(`{${arg}}`, 'g'), args[arg]);
      });
    }
    return message;
  }



  getVerifyMessage(err) {
    if (err) {
      err = err.replace('400 - Bad Request ', '');
      err = err.replace('"', '');
      err = err.replace('"', '');
      return err;
    } else {
      return 'Server is down Please contact to administrator.';
    }
  }

  getAge(birth) {
    const today = new Date();
    const nowyear = today.getFullYear();
    const nowmonth = today.getMonth() + 1;
    const nowday = today.getDate();
    const birthyear = birth.split('/')[2];
    const birthmonth = birth.split('/')[1];
    const birthday = birth.split('/')[0];
    let age = nowyear - birthyear;
    const age_month = nowmonth - birthmonth;
    const age_day = nowday - birthday;
    if (age_month < 0 || (age_month == 0 && age_day < 0)) {
      age = age - 1;
    }
    return age;
  }

  checkemptyArray(obj) {
    if (obj && obj.length > 0) {
      return true;
    } else {
      return false;
    }
  }


}
