import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { HeaderStatusService } from 'src/app/common/services/header-status.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { ValidationConstant } from '../../../../../common/validation/validation.constant';
import { Validator } from '../../../../../common/validation/validator';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  addContactInfoForm: any;
  submitted = false;
  addContactInfoFormErrors: any = {};
  validator: Validator;
  @Output() nextFormOpen = new EventEmitter<string>();
  @ViewChild('div') div: ElementRef;
  @ViewChild('inputPhone', { static: false }) inputPhone: ElementRef;
  config = {
    FirstName: {
      required: { name: ValidationConstant.contactInfo.firstName.name },
      pattern: { name: ValidationConstant.contactInfo.firstName.name },
      maxlength: {
        name: ValidationConstant.contactInfo.firstName.name,
        max: ValidationConstant.contactInfo.firstName.maxLength.toString(),
      },
    },
    LastName: {
      required: { name: ValidationConstant.contactInfo.lastName.name },
      pattern: { name: ValidationConstant.contactInfo.lastName.name },
      maxlength: {
        name: ValidationConstant.contactInfo.lastName.name,
        max: ValidationConstant.contactInfo.lastName.maxLength.toString(),
      },
    },
    Email: {
      required: { name: ValidationConstant.contactInfo.email.name },
      pattern: { name: ValidationConstant.contactInfo.email.name },
      maxlength: {
        name: ValidationConstant.contactInfo.email.name,
        max: ValidationConstant.contactInfo.email.maxLength.toString(),
      },
    },
    Phone: {
      required: { name: ValidationConstant.contactInfo.phone.name },
      pattern: { name: ValidationConstant.contactInfo.phone.name },
      maxlength: {
        name: ValidationConstant.contactInfo.phone.name,
        max: ValidationConstant.contactInfo.phone.mask_maxLength.toString(),
      },
      minlength: {
        name: ValidationConstant.contactInfo.phone.name,
        min: ValidationConstant.contactInfo.phone.minLength.toString(),
      },
    },
    Company: {
      required: { name: ValidationConstant.contactInfo.company.name },
      maxlength: {
        name: ValidationConstant.contactInfo.company.name,
        max: ValidationConstant.contactInfo.company.maxLength.toString(),
      },
    },
    Provide: {
      required: { name: ValidationConstant.contactInfo.provide.name },
    },
    paymentPurpose: {
      required: { name: ValidationConstant.contactInfo.paymentPurpose.name },
    },
    PolicyNumber: {
      required: { name: ValidationConstant.contactInfo.policyNumber.name },
      maxlength: {
        name: ValidationConstant.contactInfo.policyNumber.name,
        max: ValidationConstant.contactInfo.policyNumber.maxLength.toString(),
      },
    },
    AccountNumber: {
      required: { name: ValidationConstant.contactInfo.accountNumber.name },
      maxlength: {
        name: ValidationConstant.contactInfo.accountNumber.name,
        max: ValidationConstant.contactInfo.accountNumber.maxLength.toString(),
      },
    },
    InvoiceNumber: {
      required: { name: ValidationConstant.contactInfo.invoiceNumber.name },
      maxlength: {
        name: ValidationConstant.contactInfo.invoiceNumber.name,
        max: ValidationConstant.contactInfo.invoiceNumber.maxLength.toString(),
      },
    },
    Other: {
      required: { name: ValidationConstant.contactInfo.other.name },
      maxlength: {
        name: ValidationConstant.contactInfo.other.name,
        max: ValidationConstant.contactInfo.other.maxLength.toString(),
      },
    },
  };
  selectedCountryCode: string;
  constructor(
    private fb: UntypedFormBuilder,
    private headerService: HeaderStatusService,
    private storageService: StorageService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.validator = new Validator(this.config);
  }
  ngAfterViewInit() {
    const refinput = this.el.nativeElement.querySelectorAll('input');

    const label = this.el.nativeElement.querySelectorAll('label');
    if (refinput.length > 0) {
      for (let item of refinput) {
        if (item.type == 'text' || item.type == 'phone') {
          let label = item.parentNode.getElementsByTagName('label')[0];
          let inline = item.parentNode;
          item.addEventListener('focus', () => {
            label.classList.add('animate');
            //inline.classList.add('move');
          });
          item.addEventListener('focusout', () => {
            if (item.value == '') label.classList.remove('animate');
            //inline.classList.remove('move');
          });
        }
      }
    }
    const inputPhone = this.inputPhone.nativeElement;
    const iti = intlTelInput(inputPhone, {
      initialCountry: 'us',
      separateDialCode: true,
    });
    this.selectedCountryCode = iti.getSelectedCountryData().dialCode;

    inputPhone.addEventListener('countrychange', () => {
      iti.setNumber('');
      this.selectedCountryCode = iti.getSelectedCountryData().dialCode;
    });
  }

  ngOnInit(): void {
    this.createContactInfoForm();
    this.addContactInfoForm.valueChanges.subscribe((_data: any) =>
      this.onValueChanged('addContactInfoForm')
    );
  }

  createContactInfoForm() {
    this.addContactInfoForm = this.fb.group({
      FirstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            ValidationConstant.contactInfo.firstName.maxLength
          ),
          Validators.pattern(ValidationConstant.accountHolderNameRegex),
        ],
      ],
      LastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            ValidationConstant.contactInfo.lastName.maxLength
          ),
          Validators.pattern(ValidationConstant.accountHolderNameRegex),
        ],
      ],
      Company: [
        '',
        [
          //  Validators.required,
          //  Validators.maxLength(ValidationConstant.contactInfo.company.maxLength),
          // Validators.pattern(ValidationConstant.spaceNotAccepted_regex),
        ],
      ],
      // CountryCode: [this.countryListWithCode[0].isdCode, [Validators.required]],
      Phone: [
        '',
        [
          Validators.required,
          Validators.maxLength(ValidationConstant.contactInfo.phone.maxLength),
          Validators.minLength(ValidationConstant.contactInfo.phone.minLength),
          Validators.pattern(ValidationConstant.phoneFormat_regex),
        ],
      ],
      Email: [
        '',
        [
          Validators.required,
          Validators.maxLength(ValidationConstant.contactInfo.email.maxLength),
          Validators.pattern(ValidationConstant.email_regex),
        ],
      ],
      // paymentPurpose: ['', [Validators.required]],
      Provide: ['PolicyNumber', [Validators.required]],
      PolicyNumber: ['', [Validators.required, Validators.maxLength(ValidationConstant.contactInfo.policyNumber.maxLength),]],
      AccountNumber: ['', [Validators.maxLength(ValidationConstant.contactInfo.accountNumber.maxLength),]],
      InvoiceNumber: ['', [Validators.maxLength(ValidationConstant.contactInfo.invoiceNumber.maxLength),]],
      Other: ['', [Validators.maxLength(ValidationConstant.contactInfo.other.maxLength),]],
    });
  }

  get formcontrol() {
    return this.addContactInfoForm?.controls;
  }

  addValidation(controlName: any) {
    setTimeout(() => {
      this.refreshEvent();
    }, 0);

    let controls = ['PolicyNumber', 'AccountNumber', 'InvoiceNumber', 'Other'];
    controls.forEach((item) => {
      if (item == controlName) {
        this.addContactInfoForm
          .get(controlName)
          .addValidators([Validators.required]);
        this.addContactInfoForm?.get(controlName)?.updateValueAndValidity();
      } else {
        this.addContactInfoForm?.get(item)?.clearValidators();
        this.addContactInfoForm?.get(item)?.updateValueAndValidity();
        this.addContactInfoForm?.get(item)?.setValue('');
      }
    });
  }
  refreshEvent() {
    const refInput = this.el.nativeElement.querySelectorAll('input');
    if (refInput.length > 0) {
      for (let item of refInput) {
        if (item.type == 'text' || item.type == 'phone') {
          let label = item.parentNode.getElementsByTagName('label')[0];
          let inline = item.parentNode;
          item.addEventListener('focus', () => {
            label.classList.add('animate');
            //inline.classList.add('move');
          });
          item.addEventListener('focusout', () => {
            if (item.value == '') label.classList.remove('animate');
            //inline.classList.remove('move');
          });
        }
      }
    }
  }
  onValueChanged(data?: any) {
    if (data === 'addContactInfoForm') {
      if (!this.addContactInfoForm) {
        return;
      }
      this.addContactInfoFormErrors = this.validator.validate(
        this.addContactInfoForm
      );
    }
  }
  openPaymentInfo() {
    this.submitted = true;
    if (this.addContactInfoForm.invalid) {
      this.validator.validateAllFormFields(this.addContactInfoForm);
      this.addContactInfoFormErrors = this.validator.validate(
        this.addContactInfoForm
      );
    } else {
      this.storageService.save(
        StorageType.session,
        'contactInfo',
        JSON.stringify(this.addContactInfoForm.value)
      );
      this.storageService.save(
        StorageType.session,
        'countryCode',
        JSON.stringify(this.selectedCountryCode)
      );
      let headerStatus = {
        contactInfo: 'completed',
        paymentInfo: 'active',
        summaryInfo: 'pending',
        termsUse: 'inactive',
        privacyPolicy: 'inactive',
      };
      this.updateStatus(headerStatus);
    }
  }

  phoneNumberValidation(event) {
    if (event.target.value.length >= 2 && event.target.value.length < 14) {
      this.addContactInfoFormErrors.Phone =
        'Phone Number must be 10 digits/characters long.';
    }
    const inputValue = (<HTMLInputElement>event.target).value.replace(/\D/g, '');
    this.addContactInfoForm.controls.Phone.patchValue(inputValue);
  }

  updateStatus(data) {
    this.headerService.updateStatus(data);
  }

  omit_cardHolderName_SupportedChars(event) {
    var k;
    k = event.charCode || event.keyCode; // Use charCode if available, otherwise fallback to keyCode

    // Allow alphabetic characters (uppercase and lowercase), numbers, spaces, commas, periods, hyphens, backticks and single quote
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 8 || k == 32 || k == 44 || k == 46 || k == 45 || k == 96 || k == 39);
  }

}
