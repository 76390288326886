import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() {
    //this is constructor
   }

  showUntillCompleted(obs: Observable<any>): Observable<any> {
    return obs;
  }

  show() {
    setTimeout(() => {
      this.loadingSubject.next(true);
    });
  }

  hide() {
    this.loadingSubject.next(false);
  }
}
