import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFormatPhone',
})
export class CustomFormatPhonePipe implements PipeTransform {
  countryCode: any;
  transform(value: any, args?: any): any {
    if (value === '--' || value === '' || value == null) {
      return '--';
    } else {
      if (value.length > 10) {
        const number = value.split('-');
        value = number[1];
        args = number[0];
      }
      if (args != null && args !== '' && args !== undefined) {
        this.countryCode = args + '-';
      } else {
        this.countryCode = '';
      }

      return this.countryCode + value.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }
  }
}
