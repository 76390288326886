export class ValidationConstant {
  static email_regex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static charactersOnlyWithSpace_regex = '^[a-zA-Z]+([a-zA-Z ]+)*$';
  static cityRegex = "^[a-zA-Z0-9.,'`-]+([a-zA-Z0-9 .,'`-]+)*$";
  static alphanumeric_regex = '^[a-zA-Z0-9]*$';
  static alphanumericWithSpace_regex = '^[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*$';
  static alphanumericWithSpaceAndHyphen_regex =
    '^[a-zA-Z0-9]+([a-zA-Z0-9 -]+)*$';

  /* Alphanumeric characters: a through z or A through Z, 0 through 9
  Special characters: Spaces, Commas, Hyphens, Periods, Apostrophes */
  static cardHolderNameRegex = /^[a-zA-Z0-9_]+([a-zA-Z0-9 ,.'`_-]+)*$/;
  static accountHolderNameRegex = "^[a-zA-Z0-9]+([a-zA-Z0-9 ,.'`-]+)*$"
  // static cardHolderNameRegex = "^(?=(?:[^0-9]*[0-9]){0,8}[^0-9]*$)[a-zA-Z0-9]+([a-zA-Z0-9 ,.'`-]+)*$";

  static numbersOnly_regex = '^[0-9]*$';
  static phoneFormat_regex = '^[0-9-]*$';
  // Need to check alternative regex for "allow everything except space"
  static spaceNotAccepted_regex =
    '^[a-zA-Z0-9~!@#$%^&*()_+{}|:"<>?`\\-=\\[\\]\\\\;\',./]+([a-zA-Z0-9~!@#$%^&*()_+{}|:"<>?`\\-=\\[\\]\\\\;\',./ \r\n|\r|\n]+)*$';
  // First Name, Last Name, Customer Name, Name on Card, Card Holder Name, Pay To The Order Of
  static firstNameLastName_regex = "^[a-zA-Z]+([a-zA-Z ,.'-]+)*$";
  static amount_regex = '^([0-9]{1,9})(\\.[0-9]{1,2})?$';
  static amount_regex_amountGreaterThanZero = new RegExp(
    /^(?=.*[1-9])\d{0,9}(?:\.\d{0,2})?$/
  );
  static amount_regex_amountGreaterThanOneLac =
    '^(?:[0-9][0-9]{0,4}(?=.*[1-9])d{0,9}(?:.d{0,2})?|100000|100000.00)$';
  // static amount_regex = '^\\d{0,9}(\\.\\d{1,2})?$';
  static invoiceNo_regex = /^[^&?=]+$/;
  static percentage_regex =
    '(^100(\\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\\.[0-9]{1,2})?$)';
  static cashDiscountAndSurchargePercentage_regex =
    '(^[0-3](.[0-9]{1,2})?$|(4.00|4)?$)';
  static setReminder_regex = '^([1-9]|10)';
  static subDomain_regex = '^[a-z0-9]*$';
  static countGreaterThanZero_regex = '^[1-9][0-9]*$';
  //combining ipv4, ipv6,ipv4 range, ipv6 range to form this new regex

  static percentage_regexwithZero = '(^([1-9]([0-9])?|0)(\\.[0-9]{1,2})?$)';
  static percentage_regexWithoutZero = new RegExp(
    /^([1-9]\d?(\.\d\d?)?|0\.[1-9]\d?|0\.0[1-9])$|(100.00|100.0|100)$/
  );

  static numberOfPayments_regex =
    '^([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0])$';
  static numberOfPayments_regexForInstallment =
    /^(?:[1-9]\d?|[12]\d{2}|3[0-5]\d|360)$/;
  static numberOfPayments_regexForSubscription =
    '^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$';
  // static numberOfPayments_regex = '^(36[0]|3[0-5][0-9]|[12][0-9][0-9]|[1-9]?[0-9])?$';
  static numberOfIntervals_regex =
    '^([0-9]|0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-8])$';
  static timeInMinutes_regex =
    '^(0?[1-9]|00?[1-9]|000?[1-9]|0?[1-9][0-9]|00[1-9][0-9]|0?[1-9][0-9]{2}|[1-2][0-9]{3}|3[0-5][0-9]{2}|3600)$';
  static zipCode_regex = '^[0-9]{5}([- /]?[0-9]{4})?$';

  static zipcode_USA_regex = '^[0-9]{5}(?:-[0-9]{4})?$';
  static zipcode_Canada_regex =
     '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] [0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]';
	     ' /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/';

  static number1to99_regex = '^([0-9]|[1-9][0-9])$';
  static numberRegex = /^[\d]*$/;
  static cardNumberRegex = new RegExp('^[0-9]{12,19}$');

  // between 14 - 50 characters, one number,one upper and lower case character and one special character
  static passwordRegex =
    "^(?=.*[0-9])(?=.*[!@#$%^&'+\\=\\-\\_\\|\\(\\)\\{\\}\\[\\])*])[a-zA-Z0-9!@#$%^&*'\\=\\-+\\_\\|\\(\\)\\{\\}\\[\\]]{13,49}$";
  // static passwordRegex = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{13,49}';

  static common = {
    title: { name: 'Title', minLength: 5, maxLength: 50 },
    firstName: { name: 'First Name', minLength: 5, maxLength: 50 },
    middleName: { name: 'Middle Name', minLength: 5, maxLength: 50 },
    lastName: { name: 'Last Name', minLength: 5, maxLength: 50 },
    companyName: { name: 'Company Name', maxLength: 50, minLength: 5 },
    department: { name: 'Department', maxLength: 50, minLength: 5 },
    fax: { name: 'Fax', maxLength: 20, minLength: 10 },
    countryCode: { name: 'Country Code', maxLength: 10, minLength: 2 },
    phone: {
      name: 'Phone Number',
      maxLength: 12,
      minLength: 10,
      mask_minLength: 10,
      mask_maxLength: 10,
    },
    alternatePhone: { name: 'Alternate Phone', maxLength: 10, minLength: 5 },
    mobile: { name: 'Mobile', maxLength: 50, minLength: 5 },
    email: { name: 'Email', maxLength: 256, minLength: 5 },
    url: { name: 'URL', maxLength: 50, minLength: 5 },
    addressLine1: { name: 'Address Line 1', maxLength: 50, minLength: 5 },
    addressLine2: { name: 'Address Line 2', maxLength: 50, minLength: 5 },
    city: { name: 'City', maxLength: 50, minLength: 5 },
    state: { name: 'State', maxLength: 50 },
    country: { name: 'Country', maxLength: 50 },
    postalCode: { name: 'Postal (Zip) Code', maxLength: 10, minLength: 5 },

    invoiceNo: { name: 'Invoice No', minLength: 1, maxLength: 16 },
    date: { name: 'Date' },
    startDate: { name: 'Start Date' },
    endDate: { name: 'End Date' },
    amount: { name: 'Amount' },
    customerName: { name: 'Customer Name', minLength: 1, maxLength: 101 },
    status: { name: 'Status' },
    paymentFor: { name: 'Payment For', minLength: 1, maxLength: 50 },
    description: { name: 'Description', minLength: 5, maxLength: 1000 },

    channelSubType: { name: 'Channel Sub Type' },
  };

  static login = {
    username: { name: 'User Name', maxLength: 50, minLength: 7 },
    password: { name: 'Password', maxLength: 50, minLength: 8 },
  };

  static forgotPassword = {
    username: { name: 'User Name', maxLength: 50, minLength: 7 },
  };

  static changePassword = {
    oldPassword: { name: 'Old Password', maxLength: 50, minLength: 14 },
    newPassword: { name: 'New Password', maxLength: 50, minLength: 14 },
    confirmPassword: { name: 'Confirm Password', maxLength: 50, minLength: 14 },
  };

  static settings = {
    businessName: { name: 'Business Name ', maxLength: 60, minLength: 7 },
    logo: { name: 'Logo', maxLength: 50, minLength: 8 },
    description: { name: 'Description', maxLength: 280, minLength: 7 },
    mobileNumber: { name: 'Mobile Number', maxLength: 10, minLength: 10 },
    email: { name: 'Email', maxLength: 50, minLength: 7 },
  };

  static customer = {
    add: {
      title: { name: 'Title', minLength: 2, maxLength: 10 },
      firstName: { name: 'First Name', minLength: 5, maxLength: 50 },
      middleName: { name: 'Middle Name', minLength: 5, maxLength: 50 },
      lastName: { name: 'Last Name', minLength: 5, maxLength: 50 },
      company: { name: 'Company', minLength: 5, maxLength: 50 },
      department: { name: 'Department', minLength: 5, maxLength: 50 },
      phone: {
        name: 'Phone Number',
        minLength: 10,
        maxLength: 12,
        mask_minLength: 10,
        mask_maxLength: 10,
      },
      fax: { name: 'Fax', minLength: 10, maxLength: 10 },
      email: { name: 'Email', minLength: 5, maxLength: 256 },
      addressLine1: { name: 'Address Line 1', minLength: 5, maxLength: 50 },
      addressLine2: { name: 'Address Line 2', minLength: 5, maxLength: 50 },
      city: { name: 'City', minLength: 5, maxLength: 50 },
      state: { name: 'State', minLength: 5, maxLength: 50 },
      country: { name: 'Country', minLength: 5, maxLength: 50 },
      postalCode: { name: 'Postal (Zip) Code', minLength: 5, maxLength: 10 },
      partner: { name: 'Partner' },
    },
    find: {
      partnerName: { name: 'Partner Name', maxLength: 50, minLength: 5 },
      customerName: { name: 'Customer Name', maxLength: 50, minLength: 5 },
      companyName: { name: 'Comapnay Name', maxLength: 50, minLength: 5 },
      email: { name: 'Email', maxLength: 256 },
      phone: { name: 'Phone Number', minLength: 10, maxLength: 12 },
      status: { name: 'Status' },
    },
  };

  static support = {
    name: { name: 'Name', minLength: 5, maxLength: 50 },
    email: { name: 'Email', minLength: 5, maxLength: 50 },
    subject: { name: 'Subject', minLength: 5, maxLength: 50 },
    message: { name: 'Message', minLength: 5, maxLength: 250 },
  };

  static transaction = {
    searchInput: { name: 'Search', minLength: 3 },
  };

  static contactInfo = {
    firstName: { name: 'First Name', minLength: 5, maxLength: 50 },
    lastName: { name: 'Last Name', minLength: 5, maxLength: 50 },
    company: { name: 'Company', minLength: 5, maxLength: 50 },
    phone: {
      name: 'Phone Number',
      minLength: 10,
      maxLength: 10,
      mask_minLength: 10,
      mask_maxLength: 10,
    },
    email: { name: 'Email', minLength: 5, maxLength: 256 },
    provide: { name: 'Checkbox', minLength: 5, maxLength: 256 },
    policyNumber: { name: 'Policy Number', minLength: 5, maxLength: 256 },
    accountNumber: { name: 'Account Number', minLength: 5, maxLength: 256 },
    invoiceNumber: { name: 'Invoice Number', minLength: 5, maxLength: 256 },
    other: { name: 'Other', minLength: 5, maxLength: 256 },
    paymentPurpose: { name: 'Payment Purpose', minLength: 5, maxLength: 256 },
  };

  static paymentInfo = {
    amount: { name: 'Amount', minLength: 1, maxLength: 10 },
    accountHolderName: {
      name: 'Account Holder Name ',
      requireName: 'an account holder name',
      minLength: 5,
      maxLength: 50,
    },
    routingNumber: {
      name: 'Banking Routing Number',
      requireName: 'a banking routing number',
      minLength: 9,
      maxLength: 9,
    },
    accountNumber: {
      name: 'Bank Account Number',
      requireName: 'a bank account number',
      minLength: 10,
      maxLength: 17,
      mask_minLength: 10,
      mask_maxLength: 10,
    },
    verifyAccountNumber: {
      name: 'Verify Account Number',
      minLength: 10,
      maxLength: 17,
      mask_minLength: 10,
      mask_maxLength: 10,
    },
    accountType: { name: 'Account Type', minLength: 5, maxLength: 256 },
    bankName: { name: 'Bank Name', minLength: 5, maxLength: 256 },
    NameOnCard: {
      name: 'Name On Card',
      requireName: 'a name on card',
      minLength: 5,
      maxLength: 50,
    },
    CardNumber: {
      name: 'Card Number',
      requireName: 'a card number',
      minLength: 16,
      maxLength: 16,
    },
    CardNumberExpress: {
      name: 'Card Number',
      requireName: 'a card number',
      minLength: 15,
      maxLength: 15,
    },
    CardNumberDiners: {
      name: 'Card Number',
      requireName: 'a card number',
      minLength: 14,
      maxLength: 14,
    },
    cardExpiry: {
      name: 'Expiration Date',
      requireName: 'an expiration date',
      minLength: 5,
      maxLength: 5,
    },
    SecurityCode: {
      name: 'Security Code',
      requireName: 'a security code',
      minLength: 3,
      maxLength: 3,
    },
    SecurityCodeExpress: {
      name: 'Security Code',
      requireName: 'a security code',
      minLength: 4,
      maxLength: 4,
    },
    SecurityCodeDiners: {
      name: 'Security Code',
      requireName: 'a security code',
      minLength: 3,
      maxLength: 3,
    },
    country: {
      name: 'Country',
      requireName: 'country',
      minLength: 5,
      maxLength: 256,
    },
    state: {
      name: 'State',
      requireName: 'state',
      minLength: 5,
      maxLength: 256,
    },
    street: {
      name: 'Street Name',
      requireName: 'a suite/unit',
      minLength: 5,
      maxLength: 256,
    },
    houseNo: {
      name: 'Address',
      requireName: 'an address',
      minLength: 5,
      maxLength: 256,
    },
    postalCode: {
      name: 'Postal Code',
      requireName: 'a postal code',
      minLength: 5,
      maxLength: 256,
    },
    city: { name: 'City', requireName: 'a city', minLength: 5, maxLength: 50 },
    cardAgreement: { name: 'Card Agreement', minLength: 5, maxLength: 256 },
    achAgreement: { name: 'ACH Agreement', minLength: 5, maxLength: 256 },
  };
}
