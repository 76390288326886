import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent {
  @Input() searchList: any[];
  @Input() states: any[];
  @Input() selectedOption: any;
  @Input() placeholder: string;
  @Input() label: string
  @ViewChild('searchInput') searchInput: ElementRef;

  @Output() selectedOptionChange = new EventEmitter<any>();

  searchText: string = '';
  filteredOptions: any[] = [];
  combinedData: any[];

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.filteredOptions = this.states ? [...this.searchList, { separator: true }, ...this.states] : this.searchList;
  }


  selectCountryorState(option: any) {
    this.selectedOption = option;
    this.searchText = option.name;
    this.selectedOptionChange.emit(option); 
  }

  filterOptions(searchTerm: string) {
    const searchData  = this.states ? [...this.searchList, { separator: true }, ...this.states] : this.searchList;
    this.filteredOptions = searchData.filter(option =>
      option?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  removeActiveClass(option){
    const liElements = document.querySelectorAll('.dropdown-list li');
    liElements.forEach((element: HTMLElement) => {
      element.classList.remove('active');
    });
  }


ngAfterViewInit() {
  this.searchInput.nativeElement.focus();
}

}

