export class MessageSetting {

  static common = {
    error: 'Please contact to administrator.',
    errordeletereseller: 'Can not delete this reseller, since it has a child record.',
    inactiveAccount: 'Your Account is Inactive. Please contact to administrator.',
    sucess: 'Success',
    failed: 'Failed',
    // changePasswordMessage: 'Details has been changed successfully. Please login again.',   
    //changePasswordSuccessMessage: 'Your_Password changed successfully.',
    //passwordMismatch: 'New_password and confirm_password did not match.',
    //errorNewPaaswordSameAsOldPassword: 'New_password should not be same as old_password.',
    //resetPasswordMessage: 'Your_Password reset successfully.',
    Key_MatchingPassword: 'Your_Password does not meet history requirements.',
    Key_InvalidOldPassword: 'Invalid old_password.',
    sessionExpired: 'Session Expired. Please login again.',
    unathorizedToAccessResource: 'Unauthorized to access requested resource. Please login again.',
    invalidMinAndMaxAmountError: 'Please enter valid Min and Max Amount.',
    partnerNameRequiredError: 'Please enter Partner Name.',
    noResultsFound: 'No results found',
    loginPermission: 'Non-Admin Users are not allowed to Login. Please contact Administrator for more details.',
    noData: 'Data not provided',
    noName: 'Name not provided',
    UnauthorizedSupportError: 'Payment Service Currently Unavailable. Please contact to administrator.',
    unauthorizedSupportInfoError: 'Payment Service Currently Unavailable please contact :'
  };

  static login = {
    invalidCredential: 'Incorrect username or password.',
    loginSuccess: 'User logged in successfully.'
  };

  static forgotPassword = {
    failed: 'Failed',
    success: 'Success',
    common: 'Password has been sent to your registered Email Id.',
  };

  static settings = {
    failed: 'Failed',
    success: 'Success',
    settingSuccess: 'Branding settings save successfully.',
    summarySuccess: 'Transaction Summary save successfully.',
    copyurl: "Copy payment url successfully",
    copyurlError: "SubDomain is not set",
    uploadSuccess: "Logo upload successfully",
    fileFormaterror: "File format not supported"
  };

  static payments = {
    exportError: 'Please select payment record to export.',
    invalidCardError:"Please enter a valid Card Number."
  }
}
