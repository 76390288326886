import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const httpOptionsNewVersion = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'x-version': '2.1'},),
}; 
const httpOptionsNewSecondVersion = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'x-version': '2.2'},),
};

@Injectable({
  providedIn: 'root',
})
export class CommonApiFuncService {
  constructor(private http: HttpClient) {}

  get(url) {
    return this.http.get(url, {
      headers: httpOptions.headers,
    });
  }

  post(url, data) {
    return this.http.post(url, data, httpOptions);
  }

  delete(url) {
    return this.http.delete(url, httpOptions);
  }

  put(url, data) {
    return this.http.put(url, data, httpOptions);
  }

  formDataPut(url, data) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.http.put(url, data, { params, headers });
  }

  getXML(url) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'text/xml');    
    return this.http.get(url, { responseType: 'text' });
  }
  
  formDataPost(url, data) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.http.post(url, data, { params, headers });
  }

  postAPIVersion(url, data, uuidValue?) {
    let headerObj = { 'Content-Type': 'application/json' ,'x-version': '2.1'};
    if(uuidValue){
      headerObj['uuid'] = uuidValue;
    }
    const httpOptionsAPI = {
      headers: new HttpHeaders(headerObj),
    };     
    return this.http.post(url, data, {
      headers: httpOptionsAPI.headers,
    });
  }

  // Added Api Version 2.1 in header
  getNewVersion(url) {        
    return this.http.get(url, {
      headers: httpOptionsNewVersion.headers,
    });
  }

  // Added for Api Version 2.1 in header
  postNewVersion(url, data) {   
    return this.http.post(url, data, { headers: httpOptionsNewVersion.headers,});
  }
  // Added for Api Version 2.2 in header
  postAPIVersionTwoPointTwo(url, data, uuidValue?) {
    let headerObj = { 'Content-Type': 'application/json' ,'x-version': '2.2'};
    if(uuidValue){
      headerObj['uuid'] = uuidValue;
    }
    const httpOptionsAPI = {
      headers: new HttpHeaders(headerObj),
    };     
    return this.http.post(url, data, {
      headers: httpOptionsAPI.headers,
    });
  }
  postNewSecondVersion(url, data) {
    return this.http.post(url, data, { headers: httpOptionsNewSecondVersion.headers,});
  }

  putNewSecondVersion(url, data) {
    return this.http.put(url, data, { headers: httpOptionsNewSecondVersion.headers,});
  }

  getNewSecondupdateVersion(url) {
    return this.http.get(url, { headers: httpOptionsNewSecondVersion.headers,});
  }
}
