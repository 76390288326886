import { Injectable } from '@angular/core';
import { StorageType } from '../enum/storage.enum';
import { CookieService } from 'ngx-cookie-service';
import { AppSetting } from '../../common/constants/appsetting.constant';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  prefix = 'nav';

  constructor(private cookieService: CookieService) {}

  save(type: StorageType, key: string, value: string, _duration?) {
    if (type === StorageType.local) {
      localStorage.setItem(this.prefix + key, this.encryptBase64(value));
    } else if (type === StorageType.session) {
      sessionStorage.setItem(this.prefix + key, this.encryptBase64(value));
    } else if (type === StorageType.cookie) {
      const date = new Date();
      date.setDate(date.getDate() + AppSetting.cookieExpiry);
      this.cookieService.set(this.prefix + key, this.encryptBase64(value), {
        expires: date,
      });
    }
  }

  get(type: StorageType, key: string) {
    if (type === StorageType.local) {
      if (localStorage.getItem(this.prefix + key)) {
        return this.decryptBase64(localStorage.getItem(this.prefix + key));
      }
      return;
    }
    if (type === StorageType.session) {
      if (sessionStorage.getItem(this.prefix + key)) {
        return this.decryptBase64(sessionStorage.getItem(this.prefix + key));
      }
      return null;
    }
    if (type === StorageType.cookie) {
      if (this.cookieService.get(this.prefix + key)) {
        return this.decryptBase64(this.cookieService.get(this.prefix + key));
      }
      return null;
    }
  }

  checkCookie(key: string) {
    return this.cookieService.check(this.prefix + key);
  }

  remove(type: StorageType, key: string) {
    if (type === StorageType.local) {
      localStorage.removeItem(this.prefix + key);
    }
    if (type === StorageType.session) {
      sessionStorage.removeItem(this.prefix + key);
    }
    if (type === StorageType.cookie) {
      this.cookieService.delete(this.prefix + key);
    }
  }

  encryptBase64(stringData: string) {
    return window.btoa(encodeURIComponent(stringData));
  }
  decryptBase64(stringData: string) {
    return decodeURIComponent(window.atob(stringData));
  }
}
