import { environment } from 'src/environments/environment';

export class AppSetting {
  static baseUrl = environment.api_url;

  static setting = {
    settings: AppSetting.baseUrl + 'settings',
  };

  static features = {
    featuresByName: AppSetting.baseUrl + 'features/{featureId}/access',
  };

  static paymentInformation = {
    binLookUp: AppSetting.baseUrl + 'binlookup',
    routingLookUp: AppSetting.baseUrl + 'routingdetails',
    paymentApi: AppSetting.baseUrl + 'payments',
    sendRececipt: AppSetting.baseUrl + 'receipts',
  };

  static common = {
    getCountry: AppSetting.baseUrl + 'countries',
    getStateList: AppSetting.baseUrl + 'countries/{countryId}/states',
    getState: AppSetting.baseUrl + 'countries/{countryId}/states/{stateId}',
    getTimeZone: AppSetting.baseUrl + 'timezones',
  };

  static cookieExpiry = 90; // days
  static dummyUser = 'dummyUser';
  static paymentFormType = "SubDomain";
}
