import { Component, OnInit } from '@angular/core';
import { HeaderStatusService } from 'src/app/common/services/header-status.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private headerService: HeaderStatusService) {}

  ngOnInit(): void {
    //ngOnInit method
  }

  closePrivacy() {
    let headerStatus = {
      contactInfo: 'active',
      paymentInfo: 'pending',
      summaryInfo: 'pending',
      termsUse: 'inactive',
      privacyPolicy: 'inactive',
    };
    this.updateStatus(headerStatus);
  }

  updateStatus(data) {
    this.headerService.updateStatus(data);
  }
}
