import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { AppSetting } from 'src/app/common/constants/appsetting.constant';
import { CommonApiFuncService } from 'src/app/common/services/common-api-func.service';
import { CommonService } from 'src/app/common/services/common.service';
import { StorageService } from 'src/app/common/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentInformationService {
  constructor(
    private commonAPIFuncService: CommonApiFuncService,
    private storageService: StorageService,
    private commonService: CommonService,
    private http: HttpClient
  ) {}

  binLookUp(data?) {
    let url = AppSetting.paymentInformation.binLookUp;
    url = `${url}${this.commonService.buildQuery(data)}`;
    return this.commonAPIFuncService.get(url).pipe(
      tap((_) => this.commonService.log(`getApiKeys`)),
      catchError(this.commonService.handleError('getApiKeys'))
    );
  }

  routingLookUp(data?) {
    let url = AppSetting.paymentInformation.routingLookUp;
    url = `${url}${this.commonService.buildQuery(data)}`;
    return this.commonAPIFuncService.get(url).pipe(
      tap((_) => this.commonService.log(`getApiKeys`)),
      catchError(this.commonService.handleError('getApiKeys'))
    );
  }

  paymentAPI(data, uuidValue) {
    return this.commonAPIFuncService
      .postAPIVersionTwoPointTwo(AppSetting.paymentInformation.paymentApi, data, uuidValue)
      .pipe(
        tap((_) => this.commonService.log(`paymentApi`)),
        catchError(this.commonService.handleError('paymentApi'))
      );
  }

  sendReceiptEmail(data) {
    return this.commonAPIFuncService
      .post(AppSetting.paymentInformation.sendRececipt, data)
      .pipe(
        tap((_) => this.commonService.log(`sendReceiptEmail`)),
        catchError(this.commonService.handleError('sendReceiptEmail'))
      );
  }
}
