import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SecureComponent } from './modules/secure/secure.component';
import { NotFoundComponent } from './modules/layouts/not-found/not-found.component';
import { AccessDeniedComponent } from './modules/secure/payment/component/access-denied/access-denied.component';

const routes: Routes = [
  // { path: '', redirectTo: '', pathMatch: 'full'},
  { path: '', component: SecureComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
