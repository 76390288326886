import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { AppSetting } from 'src/app/common/constants/appsetting.constant';
import { CommonApiFuncService } from 'src/app/common/services/common-api-func.service';
import { CommonService } from 'src/app/common/services/common.service';
import { StorageService } from 'src/app/common/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private commonAPIFuncService: CommonApiFuncService,
    private storageService: StorageService,
    private commonService: CommonService,
    private http: HttpClient
  ) {}

  settings(data) {
    return this.commonAPIFuncService
      .postNewVersion(AppSetting.setting.settings, data)
      .pipe(
        tap((_) => this.commonService.log(`settings`)),
        catchError(this.commonService.handleError('settings'))
      );
  }

  features(data) {
    let url = AppSetting.features.featuresByName.replace('{featureId}', data.featureId);
    return this.commonAPIFuncService.postNewVersion(url, data.reqObj).pipe(
      tap((_) => this.commonService.log(`features`)),
      catchError(this.commonService.handleError('features'))
    );
  }

}
